import React, { useRef } from "react";
import * as PR from '../../prime-modules'
import AppleSignin from 'react-apple-signin-auth';
//import { authActions } from '../../store/auth';
import { envConfig } from "../../constants";
import { useMutation } from "react-query";
import { appleLoginAPI } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../store/auth.slice";
import { useToast } from "../../context/ToastContext";

import getAssets from "../../assets/data/brandingAssets.json"

let {apple_icon} = getAssets.icons

const AppleLogin = ({labels}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {showToast} = useToast()
    const toast = useRef();

    const {mutate, isLoading} = useMutation(appleLoginAPI, {
        onSuccess: data => {navigate('/'); dispatch(login(data))} 
    })

    const appleResponse = res => {
        if (!res.error)  mutate(res)
        else showToast("error",res.error?? labels?.LBLN0283)

    };

    return (
        <div className={`col-12 md:col-6 ${isLoading  ? "btn-disable " : ""}`}>
            <AppleSignin
                authOptions={{
                    clientId: envConfig.REACT_APP_APPLE_CLIENT_ID,
                    redirectURI: envConfig.REACT_APP_APPLE_CALLBACK_URL,
                    scope: 'email name',
                    state: 'state',
                    nonce: 'nonce',
                    usePopup: true,
                }}
                onSuccess={appleResponse}
                render={props => {
                    return <div {...props} className="wrapper">
                    <PR.Button
                      type="button"
                      className="social-button"
                      icon={isLoading && "pi pi-spin pi-spinner"}
                      disabled={isLoading}
                    >
                      <span className="button-label">{labels?.LBLN0073}</span>
                      <PR.Image src={apple_icon} alt="Apple" />
                    </PR.Button>
                  </div>
                }}
            />
            <PR.Toast ref={toast}/>
        </div>
    );
}
 
export default AppleLogin;