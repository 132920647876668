import React, { useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from 'react-redux';

import * as PR from "../../prime-modules/index";
import Layout from "../../components/layout/Layout";
import "./Signup.scss";
import Input from "../../components/form/Input";
import { signUpValidationSchema } from "../../utils/schema";
import { handleSignup } from "../../services/api";
import { ALL_COUNTRY, USER_EMAIL } from "../../utils/localstore";
import GoogleLogin from "../../components/auth/GoogleLogin";
import AppleLogin from "../../components/auth/AppleLogin";
import { useToast } from "../../context/ToastContext";
import { removeAfid } from "../../store/auth.slice";

const Signup = () => {

    const formikRef = useRef(null);
    const {showToast} = useToast()
    const navigate = useNavigate();
    const { selectedCountry } = useSelector(state => state.country);
    const {labels} = useSelector(state=>state.language?.activelang);
    const allCountries = JSON.parse(localStorage.getItem(ALL_COUNTRY));
    const dispatch = useDispatch()

    const initialValues = {
        firstName: '',
        email: '',
        phone: `${allCountries?.filter(e=> selectedCountry?.iso===e.iso)?.[0]?.callingCode}`,
        password: '',
        confirmPassword: ''
    }

    const {mutate,  isLoading} = useMutation(handleSignup,{
        onSuccess:(o)=>{
            showToast("success",labels?.LBLN0282)
            localStorage.setItem(USER_EMAIL,formikRef.current.values.email);
            formikRef.current.resetForm();
            dispatch(removeAfid())
            navigate('/signin');
        },
        onError:(o)=> showToast("error",o?.message)

    })

    const submitHanlder  = value => {
        const [callingCode, localPhoneNumber] = `+${value?.phone}`?.split('-');
        mutate({ ...value, phone: { callingCode, localPhoneNumber }});
    }

    return (
    <>
        <Layout>
            <section className="signup-section">
                <div className="grid">
                    <div className="col-12 text-center">
                        <h1>{labels?.LBLN0232}</h1>
                        <p>{labels?.LBLN0312}</p>
                        <div className="signup-wrapper">
                            {/* Form section starts here */}
                            <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={signUpValidationSchema} onSubmit={submitHanlder} enableReinitialize={true} >
                                <Form autoComplete="off" className="form-block" >
                                    <div className='grid'>
                                        <div className="col-12">
                                            <Input id={'firstName'} name={'firstName'} label={labels?.LBLN0001} type={'text'} aria-describedby={'f-name'} placeholder={labels?.LBLN0207} />
                                        </div>
                                        {/* <div className="col-12 md:col-6">
                                            <Input id={'lastName'} name={'lastName'} label={labels?.LBLN0002}  type={'text'} aria-describedby={'l-name'} placeholder={labels?.LBLN0208} />
                                        </div> */}
                                        <div className="col-12 md:col-12">
                                            <Input id={'email'} name={'email'} label={labels?.LBLN0037} type={'email'} aria-describedby={'email'} placeholder={labels?.LBLN0209} />
                                        </div>
                                        <div className="col-12 md:col-12">
                                            <Input id={'phone'} name={'phone'} label={labels?.LBLN0011} type={'text'} aria-describedby={'email'} placeholder={labels?.LBLN0213} />    
                                        </div>
                                        <div className="col-12 md:col-6">
                                            <Input id={'password'} name={'password'} feedback={false} type={'password'}  label={labels?.LBLN0071} aria-describedby={'password'} toggleMask placeholder={labels?.LBLN0228} />    
                                        </div>
                                        <div className="col-12 md:col-6">
                                            <Input id={'confirmPassword'} name={'confirmPassword'} feedback={false} type={'password'} label={labels?.LBLN0005} aria-describedby={'confirm-password'} toggleMask placeholder={labels?.LBLN0229} />    
                                        </div>
                                        <div className="col-12">
                                            <PR.Button disabled={isLoading} label={labels?.LBLN0226} type='submit' className="signup-button w-12"/>
                                        </div>
                                    </div>
                                      
                                    <div className="grid mt-4 mb-3">
                                      <GoogleLogin labels={labels} />
                                      <AppleLogin labels={labels} />
                                    </div>
                                    <p className="link-p">{labels?.LBLN0087} <Link to="/signin">{labels?.LBLN0068}</Link></p>
                                </Form>
                            </Formik>
                            {/* Form section ends here */}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    </>
  );
};

export default Signup;
