import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import "./Home.scss";
import Layout from "../../components/layout/Layout";
import BundleSection from '../../components/bundle/BundleSection';
import ContactUs from '../../components/home/ContactUs';
import StoreLinks from '../../components/home/StoreLinks';
import AboutUs from '../../components/home/AboutUs';
import ActivationSteps from '../../components/home/ActivationSteps';
import GlobalCoverage from '../../components/home/GlobalCoverage';
import Faq from '../../components/home/Faq';
import AffiliateSection from '../../components/home/AffiliateSection';
import ContectInfo from '../../components/home/ContectInfo';
import { getImageSrc } from '../../utils/reuse';
import getAssets from "../../assets/data/brandingAssets.json"


const Home = () => {
    const navigate = useNavigate()
    const {labels,home, validations} = useSelector(state=>state.language?.activelang);

    const location = useLocation();
    useEffect(() => {
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, [location]);

    return (
    <>
        <Layout>
            {/**** Header Section ****/}
            <section className="header-text-section">
                <div className="flex align-items-center justify-content-center">
                    <div className="header-text">
                    <h1>
                      {home?.HOMEN0002 && home?.HOMEN0002.split(' ').map((word, index) => index === 1 || index === 2 ? ( <span key={index}>{word}{" "}</span>) :
                     (<span style={{color:"#fff"}} key={index}>{word}{" "}</span>))}
                    </h1>
                        <p>{home?.HOMEN0003}</p>
                    </div>
                </div>
            </section>

            {/**** Bundles Section ****/}
            <BundleSection />

            {/**** Mobile application Section ****/}
            <StoreLinks home={home}/>

            {/**** Who We Are Section ****/}
            <AboutUs home={home}/>

            {/**** Activation Steps Section ****/}
            <ActivationSteps home={home} />

            {/**** Global coverage Section ****/}
            <GlobalCoverage navigate={navigate} home={home} />

            {/**** FAQ Section ****/}
             <Faq navigate={navigate} labels={labels} home={home} />

            {/**** ContactUs Section ****/}
            <ContactUs labels={labels} validations={validations} home={home}/>

            {/*** Affiliate Section ***/}
            <AffiliateSection titleFirst={home?.HOMEN0063} titleLast={home?.HOMEN0064} affiliate={getImageSrc(home?.HOMEN0086, getAssets.home?.affiliate)} page="/affiliate" />

           {/*** ContactInfo Section ***/}
            <ContectInfo home={home}/>
        </Layout>
    </>
  );
};

export default Home;