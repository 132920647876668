import React, { useRef, useState } from "react";
import TimeAgo from "timeago-react";
import { useQuery } from "react-query";
import DOMPurify from "dompurify";
import { useSelector } from "react-redux";

import * as PR from "../../prime-modules/index";
import Layout from "../../components/layout/Layout";
import { getNotifications } from "../../services/api";
import Loading from "../../components/Loading";
import "./Notifications.scss";
import getAssets from "../../assets/data/brandingAssets.json"

let { mail } = getAssets.home

const Notifications = () => {
  const pageNumber = useRef(0)
  const { myAccount: account } = useSelector(state => state.language?.activelang);
  const [notifications, setNotifications] = useState(null)
  const endReached = useRef(false)
  let limit = 20
  const { isLoading, refetch } = useQuery({
    queryFn: getNotifications.bind(
      null,
      "offset=" + pageNumber.current * limit + "&limit=" + limit
    ),
    queryKey: [pageNumber.current],
    refetchOnWindowFocus: false,
    onSuccess: (result) => {
      setNotifications(state => (!state ? result : [...state, ...result]))
      if (endReached.current === false && result?.length < 20) {
        endReached.current = true
      }
    }
  });

  window.onscroll = function () {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      if (!endReached.current) {
        pageNumber.current += 1
        refetch()
      }
    }
  };

  return (
    <Layout>
      <div className="notifications-section">
        <div className="text-center">
          <p>{account?.ACTN0066}</p>
          <h1>{account?.ACTN0095}</h1>
        </div>
        {!notifications ? <Loading /> : <>
          {notifications.length === 0 ?
            <p className="message-tag">{account?.ACTN0049}</p> : <div className="notifications-list">
              <PR.Accordion className="notifications-accordion">
                {notifications.map((notification, index) => (
                  <PR.AccordionTab
                    key={index}
                    header={
                      <span className="flex align-items-center justify-content-between mobile-flex w-full">
                        <div className="left flex align-items-center gap-2">
                          <PR.Image
                            src={mail}
                            alt="SIM Card"
                            className="simcard-icon"
                          />
                          <span className="heading">{notification?.title}</span>
                        </div>
                        <TimeAgo datetime={notification?.date} />
                      </span>
                    }
                  >
                    <div className="flex bg-contain">
                      <p
                        className="m-0 notifications-content"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(notification.message),
                        }}
                      ></p>
                      {notification.image && (
                        <div className="qrcode-div">
                          <img
                            src={"data:image/png;base64," + notification.image}
                            alt="QRcode"
                          />
                        </div>
                      )}
                    </div>
                  </PR.AccordionTab>
                ))}
              </PR.Accordion>
              {isLoading ? <Loading /> : null}
            </div>}
        </>}
      </div>
    </Layout>
  );
};

export default Notifications;
