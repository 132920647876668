import './index.scss'
import { useSelector } from 'react-redux';

import * as PR from "../../prime-modules/index";
import getAssets from "../../assets/data/brandingAssets.json"

let { user_maintenance } = getAssets.home


const UnderMaintenance = () =>{
    const {labels} = useSelector(state=>state.language?.activelang);
    return (
        <>
            <div className="under-maintenance-section">
                <div className='container'>
                    <div className='grid grid-nogutter custom-margin'>
                        <div className="col-12 lg:col-6">
                            <span>503 {labels?.LBLN0256}</span>
                            <h1>{labels?.LBLN0259}</h1>
                            <p>{labels?.LBLN0260}</p>
                        </div>
                        <div className="col-12 lg:col-6 text-center">
                            <PR.Image src={user_maintenance} alt="404 error" className='error-image'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default UnderMaintenance