import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"

export const PublicRoute = () => {

    const { isAuthenticated, isTopupVoucher } = useSelector(state => state.auth);
    return isAuthenticated ? (isTopupVoucher ? <Navigate to='/redeem-voucher' /> : <Navigate to='/' />)  : <Outlet />;
}

export const PrivateRoute = () => {

    const { isAuthenticated } = useSelector(state => state.auth)

    return isAuthenticated ?  <Outlet /> : <Navigate to='/signin' />;
}
