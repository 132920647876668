import React from 'react'
import * as PR from "../../prime-modules/index";
import getAssets from "../../assets/data/brandingAssets.json"
import { getImageSrc } from '../../utils/reuse';

function ActivationSteps({home}) {
    let step_one = getImageSrc(home?.HOMEN0080, getAssets.home.step_one)
    let step_two = getImageSrc(home?.HOMEN0081, getAssets.home.step_two)
    let step_three = getImageSrc(home?.HOMEN0082, getAssets.home.step_three)
  return (
    <section className="steps-section" id="activation">
        <div className="steps-container">
            <div className="grid">
                <div className="col-12 text-center">
                    <h2>{home?.HOMEN0036}</h2>
                    {/* <p>{home?.HOMEN0037}</p> */}
                </div>
            </div>
            <div className="grid custom-margin">
                <div className="col-12 lg:col-4">
                    <div className='step-box'>
                        <span className='step-number'>1</span>
                        <PR.Image src={step_one} alt="Step One"/>
                        <p>{home?.HOMEN0019}</p>
                    </div>
                </div>
                <div className="col-12 lg:col-4">
                    <div className='step-box middle'>
                        <PR.Image src={step_two} alt="Step Two"/>
                        <p>{home?.HOMEN0022}</p>
                        <span className='step-number center'>2</span>
                    </div>
                </div>
                <div className="col-12 lg:col-4">
                    <div className='step-box'>
                        <span className='step-number'>3</span>
                        <PR.Image src={step_three} alt="Step Three"/>
                        <p>{home?.HOMEN0025}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ActivationSteps