
import { useEffect, useState } from "react"
import { client } from "../../services/api";
import UnderMaintenance from ".";

const MaintaincePageProvider = ({ children }) => {

    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        
        const res = res => res;

        const err = err => {
            if (!!err.response.headers['siteundermaintenance']) setEnabled(err.response.headers['siteundermaintenance']==='true');
            return Promise.reject(err);
        }

        const interceptor = client.interceptors.response.use(res, err);

        return () => client.interceptors.response.eject(interceptor);

    }, [])

    return enabled ? <UnderMaintenance /> : children;
}

export default MaintaincePageProvider;