import './index.scss'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as PR from "../../prime-modules/index";
import getAssets from "../../assets/data/brandingAssets.json"

let { page_not_found } = getAssets.home


const NotFoundPage = () =>{
    const {labels} = useSelector(state=>state.language?.activelang);
    const navigate = useNavigate()
    return (
        <>
            <div className="page-404-section">
                <div className='container'>
                    <div className='grid custom-margin'>
                        <div className="col-12 lg:col-6">
                            <span>404 {labels?.LBLN0256}</span>
                            <h1>{labels?.LBLN0257}</h1>
                            <p>{labels?.LBLN0258}</p>
                            <PR.Button label={labels?.LBLN0158} onClick={navigate.bind(null,"/")} type='submit' className="goto-button w-12"/>
                        </div>
                        <div className="col-12 lg:col-6 text-center">
                            <PR.Image src={page_not_found} alt="404 error" className='error-image'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default NotFoundPage