import { useField, useFormikContext } from "formik";
import * as PR from "../../prime-modules/index";
import './Form.scss'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { useSelector } from "react-redux";

const Input = (props) => {

    const [field, meta] = useField(props);
    const [, , helpers] = useField('phone');
    const { setFieldValue } = useFormikContext(); 
    const { setValue } = helpers;
    const {validations} = useSelector(state=>state.language?.activelang);

    const phoneCh = (v, data) => { setValue(data?.dialCode + '-' + v.slice(data?.dialCode.length, v.length)); }

    const handlePromoCodeChange = (e) => {
        let value = e.target.value.replace(/-/g, "").replaceAll(' ', '');
        let formattedValue = value.match(/.{1,4}/g)?.join("-") || "";
        setFieldValue("promoCode",formattedValue.toUpperCase())
    };

    return <div className="flex flex-column gap-2 input-field">
        {!!props?.label && <label htmlFor={props?.name}> {props?.label}</label>}
        {
            props?.name === 'phone'
                ? <div className="custom-phoneno-field"><PhoneInput countryCodeEditable={false} autoFormat={true} value={field.value} onChange={phoneCh} enableSearch inputProps={{ ...props, onBlur: field.onBlur, className: 'p-inputtext' }} /></div>
                : props?.type === 'password'
                    ? <PR.Password  {...field} {...props} />
                    : props?.type === "message" ? <PR.InputTextarea  {...field} {...props} />:  props?.type === 'promoCode' ?
                        <PR.InputText 
                            {...field} 
                            {...props} 
                            onChange={handlePromoCodeChange} 
                        />
                :  <PR.InputText {...field} {...props} />
        }
       {meta.touched && meta.error && <span className="err-msg">{validations[meta.error]??'Error'}</span>}
    </div>
}


export default Input