import { Form, Formik } from "formik"
import { freepaymentSchema } from "../../utils/schema"
import Input from "../../components/form/Input"
import * as PR from "../../prime-modules/index";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { CheckCompatible } from "./helper";




const FreePayment = ({onConfirm, setChecked, checked})=>{

    const { userData : userInfo } = useSelector(state=> state.auth);
    const { labels, myAccount:account} = useSelector(state=>state.language?.activelang);

    const initialValues = {
        firstName : userInfo?.firstName??'',
        email: userInfo?.email??''
    }

    const confirmHandler = value =>{
        onConfirm({
            contactInfo:{...value, phone:{localPhoneNumber:'', callingCode:''}},
            paymentMethodId: uuid(),
        })
    }

    return <Formik initialValues={initialValues} validationSchema={freepaymentSchema} onSubmit={confirmHandler} >
        <Form autoComplete="off" className="form-block" >
            <h3>{labels?.LBLN0060}</h3>
            <div className="mb-4">
                <Input name="firstName" id="firstName" type="text" placeholder={account?.ACTN0015} />
            </div>
            <div className="mb-4">
                <Input disabled={!!userInfo?.email} name="email" id="email" type="email" placeholder={labels?.LBLN0209} />
            </div>
            <CheckCompatible checked={checked} setChecked={setChecked} id='freePayment' labels={labels} />
            <PR.Button disabled={!checked.freePayment} label={labels?.LBLN0091} className="pay-button w-12" type="submit" />
        </Form>
    </Formik>
}

export default FreePayment;