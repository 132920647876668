import { v4 as uuid } from "uuid";
import { envConfig } from "../../services/api";
import { loadStripe } from "@stripe/stripe-js";
import { setNumberPrecision } from "../../utils/common";
import { globalConfig } from "../../constants";
import * as PR from "../../prime-modules/index";
import { Link } from "react-router-dom";

export const stripePromise = loadStripe(envConfig.REACT_APP_STRIPE_PUBLIC_KEY,  {
    betas: ['custom_checkout_beta_4'],
  });

export const priceFormating = ({ price, bundlePrice, discountedPrice, promoCodePrice }) => ({
    amount: parseInt((discountedPrice ?? price) * 100),
    total: setNumberPrecision(bundlePrice ?? price),
    discount: setNumberPrecision(promoCodePrice ?? 0),
    grandTotal: setNumberPrecision(discountedPrice ?? price)
})

export const options = {
    mode: 'payment',
    amount: 0,
    currency: globalConfig.defaultCurrency
};

export const optionOldPaymentMethod = {
    total: {
        label: globalConfig.paymentDefaultLabel,
        amount: 0,
    },
    country: globalConfig.paymentDefaultCountry,
    currency: globalConfig.defaultCurrency,
    requestPayerName: true,
    requestPayerEmail: true,
}


export const postData = {
    "paymentGateway": "none",
    "iccid": "",
    "isEsimCompatible": false,
    "deviceInfo": {
        "device": "website"
    },
    "randomId": uuid()
}

export const createPayPalOrder = ({ name, description, dispalyPrice: value }, _, actions) => {
    const currency_code = globalConfig.paymentDefaultCurrency?.toUpperCase()

    const purchaseUnits = {
        purchase_units: [
            {
                amount: { currency_code, value, breakdown: { item_total: { currency_code, value } } },
                items: [{ name, description, unit_amount: { currency_code, value, }, quantity: 1 },],
            },
        ],
        application_context: {
            shipping_preference: "NO_SHIPPING",
        },
    };

    return actions.order.create(purchaseUnits).then(orderID => orderID);
};

export const CheckCompatible = ({ setChecked, checked, id, labels }) => (
    <div className="flex align-items-top gap-3 mb-5">
        <PR.Checkbox onChange={() => setChecked(p => ({ ...p, [id]: !checked[id] }))} checked={checked[id]}></PR.Checkbox>
        <label htmlFor="ingredient1">{labels?.LBLN0265} <Link target="_blank" to="/compatible-devices">{labels?.LBLN0007}</Link></label>
    </div>
)


export const addressElementOptions =  {
    appearance : {
        theme: 'stripe',
        'rules': {
            '.Input': {
                'backgroundColor': 'rgba(22, 0, 51, 0.1)',
                'border': '1px solid rgba(226, 227, 238, 0.13)',
                'borderRadius': '9px',
                'padding': '20px 25px',
                'fontSize': '18px',
                'color': 'white'
    
            },
            '.Input:focus': {
                'boxShadow': '0 0 0 0.2rem #c7d2fe'
            },
            '.Label': {
                'fontSize': '18px',
                'color': 'white',
                'marginTop': '15px',
                'marginBottom': '10px',
            },
            '.Error': {
                'color': "red"
            },
        },
    },
}

export const addressOptions = {
    mode: 'billing',
    autocomplete: {
        mode: "google_maps_api",
        apiKey: envConfig.REACT_APP_GOOGLE_MAP_API_KEY
    },
    fields: {
        'phone': 'always',
    },
    validation: {
        phone: {
            required: 'always'
        }
    },
}

export const elementsOptions = {
  appearance: {
    variables: {
      colorPrimary: "#ffffff",
      colorBackground: "#0E0020",
      colorText: "#ffffff",
      colorDanger: "#ff0000",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "5px",
      borderRadius: "10px",
    },
    rules: {
      ".Input:focus": {
        borderColor: "#0E0020",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
      },
    },
  },
};

export const randomIdGenerator = (randomIdLength = 30) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
  
    for (let i = 0; i < randomIdLength; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
  
    return id.match(/.{1,6}/g).join('-');
  }
  
export const v2PaymentRequestData = {
    deviceInfo: { device: globalConfig.deviceInfo },
    randomId: randomIdGenerator(),
    isEsimCompatible: globalConfig.isEsimCompatible,
    paymentMethodId: randomIdGenerator(),
}