import moment from "moment"
import { globalConfig } from "../services/api";
export const modifyDate = (orderDate) => {
    const year = new Date(orderDate).getFullYear();
    return year !== 1 ? moment(orderDate).format("DD MMM YYYY") : "";
  };
  export const setPrice = (price) => {
    if (price == null) {
      return "";
    }
    return globalConfig.defaultCurrency + (typeof price === "number" ? setNumberPrecision(price) : price);
  };
  
  export const setNumberPrecision = (val) => {
    return val?.toFixed(2);
  };
  const formatDecimals = (num) => {
    const calcDec = Math.pow(10, 1);
    return Math.trunc(num * calcDec) / calcDec;
  }

  export const formattedDate = (timestamp) => `${new Date(timestamp).toLocaleString('en-US', { 
      day: 'numeric', 
      month: 'short', 
      year: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true 
  })}`;

  const checkFloatingValue = (formattedData) => {
    return Number.isInteger(formattedData) ? formattedData : formatDecimals(formattedData)
  }

  export const checkDataSize = (dataSizeInBytes) => {
    const bytesInOneMB = 1000 * 1000; // 1 MB = 1024 KB, 1 KB = 1024 bytes
    const bytesInOneGB = bytesInOneMB * 1000; // 1 GB = 1024 MB
    if (dataSizeInBytes >= bytesInOneGB) {
      // Data is in GB
      const dataSizeInGB = dataSizeInBytes / bytesInOneGB;
      return checkFloatingValue(dataSizeInGB) + " GB";
    } else if (dataSizeInBytes >= bytesInOneMB) {
      // Data is in MB
      const dataSizeInMB = dataSizeInBytes / bytesInOneMB;
      return checkFloatingValue(dataSizeInMB) + " MB";
    } else if(dataSizeInBytes >= 1000) {
      // Data is in bytes
      const dataSizeInMB = dataSizeInBytes / 1000;
      return checkFloatingValue(dataSizeInMB) + " KB";
    } else {
      return 0 + " KB"
    }
  };

  export const displayBase64 = (image)=>"data:image/png;base64,"+image

 export const isBase64Image = (image) => {
    if(image?.startsWith('iVBORw0KGgoAAAANSUhEUg') || image?.startsWith('/9j/4AAQSkZJRgA')){
      return true
    }
    return false
  }

export const getImageSrc = (image, local)=> image?.startsWith('iVBORw0KGgoAAAANSUhEUg') || image?.startsWith('/9j/4AAQSkZJRgA') ? "data:image/png;base64,"+image : local


export const rcTransformData = data => data?.map(i => i?.country?.name).sort();
export const snTransformData = data => data?.map(i => {
  return `${i?.name} (${i?.speeds?.join('/')})`
})

export const getPhoneObj = (phoneValue, isAuth, userData, formData) => {
  const loggedInPhone = (isAuth && userData.phone?.localPhoneNumber) ? userData.phone?.localPhoneNumber : "";
  const loggedInCallingCode = (isAuth && userData.phone?.callingCode) ? userData.phone?.callingCode : "";
  const phone = {
    localPhoneNumber: phoneValue ? phoneValue : formData?.callingCode ? "" : loggedInPhone,
    callingCode: formData?.callingCode ? formData?.callingCode : loggedInCallingCode
  }
  return phone;
}

export const checkProviderCode = (providerCode) => {
  if(providerCode) {
    return !globalConfig?.restrictProviders?.includes(providerCode);
  }
  return true;
};