import React from 'react'
import * as PR from "../../prime-modules/index";
import { snTransformData } from '../../utils/reuse';

const NetworksPopup = ({labels ,roamingEnabled,networkDialog,countries, setNetworkDialog}) => {
  return (
    <PR.Dialog closeOnEscape dismissableMask className="bundle-detail-blk" header={labels?.LBLN0253} visible={networkDialog} blockScroll={true} draggable={false} onHide={() => { if (!networkDialog) return; setNetworkDialog(false); }}>
    {(roamingEnabled.length === 0 || roamingEnabled.length === 1) ? <div className="content">
            <ul>
                {snTransformData(countries?.[0]?.networks).map((el, i) => <li key={i}>{el}</li>)}
            </ul>
        </div> : <div className="content">
         {roamingEnabled
         .sort((a, b) => a.country.name.localeCompare(b.country.name))
         .map(list=><>
            <span className="font-medium">{list?.country?.name?.split("(")[0]}</span>
         <ul>
                {snTransformData(list?.networks).map((el, i) => <li key={i}>{el}</li>)}
            </ul></>) }
        </div>}
    </PR.Dialog>
  )
}

export default NetworksPopup
