import { createSlice } from "@reduxjs/toolkit";
import { AVAILABLE_COUNTRY } from "../utils/localstore";

const localData = JSON.parse(localStorage.getItem(AVAILABLE_COUNTRY)??null);

const initialState = !!localData?localData:{
    data:[],
    ipcountry:'',
    selectedCountry: null
}

const countrySlice = createSlice({
    name:'country',
    initialState,
    reducers:{
        setCountry(state,{payload}){
            let modifiedCountryNameList = payload.data.map(country=>({...country,name:country.name.split("(")[0]}))
            payload.data = modifiedCountryNameList
            localStorage.setItem(AVAILABLE_COUNTRY,JSON.stringify(payload))
            return state=payload;
        },
        setSelectCountry(state,{payload}){
            state.selectedCountry = payload;
            localStorage.setItem(AVAILABLE_COUNTRY,JSON.stringify(state));
        }
    }
});

export const { setCountry, setSelectCountry } = countrySlice.actions;

export default countrySlice.reducer;
