import React from 'react'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

import * as PR from "../../prime-modules";

const EsimActivationDialog = ({smdpAddress,matchingId, activeeSIM, setActiveeSIM}) => {

  const {labels} = useSelector(state=>state.language?.activelang);

  return (
    <PR.Dialog header="" visible={activeeSIM} headerStyle={{ padding: 4}} draggable={false} blockScroll={true} className="eSIMActivation-dialog" resizable={false} style={{ width: '85vw'}} onHide={() => { if (!activeeSIM) return; setActiveeSIM(false); }}>
    <p className="esim-popup-title">
      {labels?.LBLN0153}
    </p>
    <p className="esim-popup-subtitle">
    {labels?.LBLN0154}
    </p>
    <Link to={`https://esimsetup.apple.com/esim_qrcode_provisioning?carddata=LPA:1$${smdpAddress}$${matchingId}`} onClick={()=>setActiveeSIM(false)} target='_blank' rel='noreferrer'>
        <PR.Button
          label={labels?.LBLN0152}
          severity="secondary"
          className="add-bundle-button"
        />
    
    </Link>
  </PR.Dialog>
  )
}

export default EsimActivationDialog
