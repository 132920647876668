import * as PR from "../../prime-modules/index";
import Layout from "../../components/layout/Layout"
import Input from "../../components/form/Input";
import { Form, Formik } from "formik";
import { otpValidateSchema } from "../../utils/schema";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import {  verifyEmail } from "../../services/api";
import { useEffect, useRef } from "react";
import { USER_EMAIL } from "../../utils/localstore";
import { useSelector, useDispatch } from "react-redux";
import { useToast } from "../../context/ToastContext";
import { login } from "../../store/auth.slice";

const initialValues = {
    otp: ''
}

const VerifyEmail = () => {
    const dispatch = useDispatch()
    const {showToast} = useToast()
    const {pathname,search} = useLocation();
    const {userData : userInfo} = useSelector(state=> state.auth);
    const {labels} = useSelector(state=>state.language?.activelang);
    const userId = Number(new URLSearchParams(search).get('id'))
    const toast = useRef(null);
    const email = localStorage.getItem(USER_EMAIL)
    // const [timer,setTimer] = useState(30);
    const navigate = useNavigate();

    useEffect(()=>{
       if(!userId && !email) navigate('/');
    },[userId,navigate,email]);

    // useEffect(()=>{
    //     const t = setInterval(() => {
    //         if(timer!=='') setTimer(prev=>prev-1);
    //     }, 1000);
    //     if(timer===0) {clearInterval(t); setTimer('')};
    //     return()=>clearInterval(t);
    // },[timer,setTimer]);

    const {mutate,isLoading} = useMutation(verifyEmail.bind(null,`${pathname.replace('-','').slice(1,pathname.length)}`),{
        onSuccess: (o) =>{
        showToast("success",labels?.LBLN0284)
        setTimeout(() => {
        // navigate('/signin') 
         dispatch(login({ ...userInfo,isEmailVerified:true }))
         navigate('/my-account')
        },2000)
        },
        onError : o =>  showToast("error",o?.message)
    });
    
    // const {mutate:mutateResendOTP, isLoading:isLoadingResend} = useMutation(resendOTP,{
    //     onSuccess: (o) =>{setTimer(30); toast.current.show({ severity: 'success', summary:('toastSummary.success'), detail: 'Sent OTP via Email' }) },
    //     onError : o => { toast.current.show({ severity: 'error', summary: ("toastSummary.error"), detail: (`errorMsg.${o?.message}`) }) }
    // });
  
    const submitHanlder = ({otp:code}) => {
        mutate({userId,code});
    };
    

    return <Layout>
        <PR.Toast ref={toast} />
        <section className="signup-section">
            <div className="grid">
                <div className="col-12 text-center">
                    <h1>{labels?.LBLN0045}</h1>
                    <p>{labels?.LBLN0046}</p>
                    <div className="signup-wrapper">
                        {/* Form section starts here */}
                        <Formik initialValues={initialValues} validationSchema={otpValidateSchema} onSubmit={submitHanlder} >
                            <Form autoComplete="off" className="form-block">
                                <div className='grid'>
                                    <div className="col-12 md:col-12">
                                        <Input 
                                            id={'otp'}
                                            name="otp"
                                            feedback={false}
                                            type={'password'} 
                                            aria-describedby={'otp'}
                                            toggleMask
                                            placeholder={labels?.LBLN0048}
                                        />
                                    </div>
                                    {/* <div className="col-4">
                                        <PR.Button disabled={isLoadingResend||!!timer} style={{fontSize:16}} onClick={mutateResendOTP.bind(null,{email,device:'string'})} label={`Resend OTP ${timer}`} type='button' className="button button-secondary w-12 mt-2"/>
                                    </div> */}
                                    <div className="col-12">
                                        <PR.Button disabled={isLoading} label={labels?.LBLN0235} type='submit' className="signup-button w-12"/>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
}
export default VerifyEmail;