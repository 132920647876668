import Layout from "../../components/layout/Layout";
import "./MyPlans.scss";
import React, { useRef, useState } from "react";
import * as PR from "../../prime-modules/index";
import { useMutation, useQuery } from "react-query";
import { editTag, fetchEsimIccids, fetchEsimDetails } from "../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { checkDataSize, checkProviderCode, displayBase64 } from "../../utils/reuse";
import { isIOS, osVersion, isMobile } from "react-device-detect";
import EdiText from "react-editext";
import EsimActivationDialog from "../../components/myplan/EsimActivationDialog";
import { useSelector } from "react-redux";
import { useToast } from "../../context/ToastContext";

const processActive = (i) => {

  let queued = 0, active = 0, expired = 0;

  const obj = {
    ...i,
    activeBundlesData: i.activeBundlesData.map(e => {
      if (e.state === 'active') active += 1;
      else if (e.state === 'queued' || e.state === 'processing') queued += 1

      else expired += 1
      return e
    })
  }
  return { ...obj, queued, active, expired }

};

const sortByDate = (a, b) => moment(b.createdTs).diff(moment(a.createdTs))

const MyPlans = () => {
  const {labels, myAccount:account, messageCatalog} = useSelector(state=>state.language?.activelang);
  const {userData} = useSelector(state=> state.auth);
  const [data, setData] = useState({active: [], pending: []})
  const navigate = useNavigate();
  const { state:activeIndex } = useLocation();
  const [expandedRows, setExpandedRows] = useState(null);
  const [showQRCode, setShowQRCode] = useState({
    status: false,
    QRCode: "",
  });
  const [activeeSIM, setActiveeSIM] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const toast = useRef();
  const {showToast} = useToast()
  
  const { isLoading: bundleListLoading } = useQuery({
    queryFn: fetchEsimIccids,
    queryKey: ["esimIccids"],
    enabled: shouldFetch,
    onSuccess: async (esimList) => {
      if (esimList?.length > 0) {
        esimList = esimList?.sort(sortByDate);
        let sortedData = { active: [], pending: [] };
        esimList?.map(async (iccidItem) => {
          const esimDetail = await fetchEsimDetails(iccidItem.iccid);
          if (esimDetail.status === 'Released' || esimDetail.status === 'Downloaded') {
          sortedData.pending = [...sortedData.pending, esimDetail];
          } else if (esimDetail.status !== 'Unknown') {
            sortedData.active = [...sortedData.active, processActive(esimDetail)];
          }
          const sortedvalues = {
            active: [...sortedData.active].sort(sortByDate),
            pending: [...sortedData.pending].sort(sortByDate)
          }
          setData(prev => ({...prev, ...sortedvalues}))
        })    
        setShouldFetch(false)
      }
    }
  });
  const getOrderInfo = (order) => {
    navigate("/bundle-details", { state: order });
  };

  const validateTagValue = (text) => {
    if (text.length === 0) {
      return true;
    } else if (text.length > 0) {
      return text.length >= 3 && text.length <= 10;
    }
  };

  const { mutate } = useMutation(editTag, {
    onSuccess: (res, variables) => {
      const {tab, index, value} = variables?.additionalFEParams;
      setData(prev =>({...prev, ...prev[tab][index].tag = value }));
      showToast("success",labels?.LBLN0280);
    },
    onError: (errorMsg) => showToast("error",errorMsg.message)

  });
  
  const tagHandleSave = (rowData, value, index, tab) => {
   
    if (validateTagValue(value) && rowData.tag !== value) {
      const params = {
        userId: rowData.userId,
        iccid: rowData.iccid,
        tag: value,
        additionalFEParams: {
          value,
          index,
          tab,
        },
      };
      mutate(params);
    }
  };

  const pendingValidityHeader = (data) => <div className="expiry-column">
    <p>{data?.activeBundlesData?.[0]?.description.split("-")[0]}</p>
  </div>


  const pendingAction = (esim) => {
    return (
      <div className="flex align-items-center gap-4">
        {esim.smdpAddress && esim.matchingId && (
          <EsimActivationDialog
            smdpAddress={esim.smdpAddress}
            matchingId={esim.matchingId}
            activeeSIM={activeeSIM}
            setActiveeSIM={setActiveeSIM}
          />
        )}
        {isMobile && isIOS && osVersion >= "17.4" ? (
          <PR.Button label={labels?.LBLN0057}
            type="submit"
            severity="secondary"
            outlined
            onClick={setActiveeSIM.bind(null, true)}
            className="add-bundle-button"
          />
        ) : (
          <>
           <PR.Button
            label={labels?.LBLN0288}
            type="submit"
            onClick={() =>
              setShowQRCode({
                status: true,
                QRCode: esim.qrCodeImage,
              })
            }
            severity="secondary"
            outlined
            className="add-bundle-button"
          />
              {process.env.REACT_APP_ENVIRONMENT !== 'prod' &&
                <PR.Button label={labels?.LBLN0293} onClick={navigate.bind(null, "/top-up", { state: { iccid: esim.iccid, providerCode: esim.providerCode } })} type='submit' severity="secondary" outlined className="add-bundle-button" />
              }
          </>
        )}
        <a href={displayBase64(esim.qrCodeImage)} download >
          <i className="pi pi-download "></i>
        </a>
        {checkProviderCode(esim.providerCode) && <i onClick={() => userData?.isEmailVerified && getOrderInfo(esim.activeBundlesData[0])} title={!userData?.isEmailVerified && messageCatalog["USRLGN-03"]} className={`pi pi-info-circle ${userData?.isEmailVerified ? '' : 'disabled'}`}></i>}
      </div>
    );
  };

  const bundleValidity = (rowData) => {
    let expiryDate = moment(rowData?.bundleEndTime).format("DD/MM/YYYY");
    let lastDate = new Date(expiryDate.split("/").reverse().join("-"));
    // Calculate the difference in days
    let daysRemaining = Math.ceil((lastDate - new Date()) / (1000 * 60 * 60 * 24));

    return (rowData?.state !== "active"
      ? <div className="expiry-column">
        <p>{!!rowData.state ? rowData.state : 'expired'}</p>
      </div>
      : <div className="expiry-column">
        <p>{expiryDate}</p>
        <p>{daysRemaining} {labels?.LBLN0018} {account?.ACTN0034}</p>
      </div>
    )
  }

  const activeBundleList = (rowData) => {
    return (
      <PR.DataTable value={rowData.activeBundlesData} stripedRows className="plans-table inner-table">
        <PR.Column body={bundleName}></PR.Column>
        <PR.Column body={bundleDetails} colSpan={2} ></PR.Column>
        <PR.Column body={bundleValidity} ></PR.Column>
        <PR.Column body={(data) => bundleDetailsAction({ ...data, providerCode:rowData?.providerCode})} className="last-column"></PR.Column>
      </PR.DataTable>
    );
  }

  const yourEsim = (tab, rowData, i) => <div className="esim-column">
    <div className="card">
      <EdiText
        name="tag"
        value={rowData.tag}
        type="text"
        placeholder={account?.ACTN0088}
        onSave={(e) => tagHandleSave(rowData, e, i.rowIndex, tab)}
        validation={validateTagValue}
        validationMessage={account?.ACTN0089}
        onCancel={(e) => e}
        className="tag-edit"
        viewContainerClassName='custom-view-wrapper'
        saveButtonClassName="custom-save-button"
        editButtonClassName="custom-edit-button"
        cancelButtonClassName="custom-cancel-button"
        viewProps={{
          className: 'custom-view-class'
        }}
      />
      <p>ICCID: {rowData.iccid}</p>
    </div>
  </div>

  const bundleDetailsHeader = ({ activeBundlesData, active, queued, expired }) => {
    return (
      activeBundlesData.length > 1 ? <div className="expiry-column">
        <p>
          {active ? `${active} Active` : ''}
          {active && queued ? ' & ' : ''}
          {queued ? `${queued} Queued` : ''}
          {(expired && queued) || (expired && active) ? ' & ' : ''}
          {expired ? `${expired} Expired` : ''}
        </p>
      </div> : bundleDetails(activeBundlesData?.[0])
    );
  };

  const bundleDetails = (rowData) => {
    let percentage = rowData?.totalData ? (rowData?.remainingData / rowData?.totalData) * 100 : 0;
    return (
      <div className="expiry-column">
        <p>{`${rowData?.country?.split("(")[0]} ${rowData?.country ? "|" : ""} ${rowData?.isUnlimited ? labels?.LBLN0167 : `${checkDataSize(rowData?.remainingData)}  / ${checkDataSize(rowData?.totalData)} ${account?.ACTN0098?? ""}`} `}</p>
        <div className="card mt-1">
          <PR.ProgressBar
            value={percentage}
            className="progress-bar"
          ></PR.ProgressBar>
        </div>
      </div>
    );
  };

  const actionHeader = bundle => <div className="flex align-items-center gap-4">
    <PR.Button label={labels?.LBLN0293} onClick={navigate.bind(null, "/top-up", { state: {iccid:bundle.iccid, providerCode: bundle.providerCode} })} type='submit' severity="secondary" outlined className="add-bundle-button" />
    {(bundle.activeBundlesData.length === 1 && checkProviderCode(bundle.providerCode)) && <i onClick={() => getOrderInfo(bundle.activeBundlesData[0])} className="pi pi-info-circle"></i>}
  </div>

  const bundleName = ({ name }) => <div className="esim-column">
    <div className="card">
      <p className="esim-name">{name}</p>
    </div>
  </div>
  const bundleDetailsAction = bundle => <div className="flex align-items-center justify-content-end  details-button">
    {checkProviderCode(bundle.providerCode) &&
      <i onClick={getOrderInfo.bind(null, bundle)} className={`pi pi-info-circle`}></i>      
    }
  </div>

  return (
    <>
      <Layout>
        <section className="signup-section myplans-section">
          <div className="grid">
            <div className="col-12 text-center">
              <h1>{labels?.LBLN0172}</h1>
              <p>{account?.ACTN0099}</p>
              <div className="plans-grid-section">
                {bundleListLoading ? (<div className="loading-div">
                  <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
                </div>
                ) : (
                  <PR.TabView activeIndex={data?.active?.length === 0 ? 1 : activeIndex ?? 0 }>
                    <PR.TabPanel header={account?.ACTN0100}>
                      <PR.DataTable emptyMessage={account?.ACTN0101} value={data?.active} stripedRows className="plans-table" expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={activeBundleList}>
                        <PR.Column body={yourEsim.bind(null,'active')} header={account?.ACTN0078}></PR.Column>
                        <PR.Column body={bundleDetailsHeader} header={account?.ACTN0102}></PR.Column>
                        <PR.Column body={esim => esim?.activeBundlesData.length === 1 ? bundleValidity(esim?.activeBundlesData[0]) : <></>} header={account?.ACTN0105}></PR.Column>
                        <PR.Column body={actionHeader} header={labels?.LBLN0163} className="last-column"></PR.Column>
                        <PR.Column expander={esim => esim.activeBundlesData.length > 1} />
                      </PR.DataTable>
                    </PR.TabPanel>
                    <PR.TabPanel header={account?.ACTN0103} >
                      <PR.DataTable emptyMessage={account?.ACTN0104} value={data?.pending} paginator rows={10} stripedRows className="plans-table" >
                        <PR.Column body={yourEsim.bind(null,'pending')} header={account?.ACTN0078} />
                        <PR.Column body={esim => bundleDetails(esim?.activeBundlesData[0])} header={account?.ACTN0102} />
                        <PR.Column body={pendingValidityHeader} header={account?.ACTN0105} />
                        <PR.Column body={pendingAction} header={labels?.LBLN0163} className="last-column" />
                      </PR.DataTable>
                    </PR.TabPanel>
                  </PR.TabView>
                )}
              </div>
            </div>
          </div>
        </section>
      </Layout>
      <PR.Toast ref={toast} position="top-right" />
      <PR.Dialog
        visible={showQRCode?.status}
        onHide={() => {
          if (!showQRCode?.status) return;
          setShowQRCode((state) => ({ ...state, status: false }));
        }}
        className="QRcode-dialog"
      >
        <PR.Image
          width="350px"
          src={displayBase64(showQRCode.QRCode)}
        />
        <p className="scan-text">
          {account?.ACTN0106}
        </p>
      </PR.Dialog>
    </>
  );
};

export default MyPlans;
