import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getlanguageAPI, googleLoginAPI } from "../../services/api";

import * as PR from "../../prime-modules/index";
import { login, logout as logoutAction, storeAfid } from "../../store/auth.slice";
import { setLanguageIntial,setLanguageStore } from "../../store/language.slice";
import getAssets from "../../assets/data/brandingAssets.json"
import { useToast } from "../../context/ToastContext";
import { AFID } from "../../utils/localstore";

let {change_password, my_account,notifications,my_plans,logout, user_icon} = getAssets.icons

const HeaderMenu = () => {

    const { i18n } = useTranslation();
    const language = i18n.language.split('-')[0];
    const navigate = useNavigate();
    const location = useLocation();
    const afid = new URLSearchParams(location.search).get(AFID);
    const userMenu = useRef(null);
    const {showToast} = useToast()
    const pathname = window.location.pathname?.toLowerCase();
    const lnFromStore = useSelector(state=>state.language);
    const affiliateURL = process.env.REACT_APP_AFFILIATE_DOMAIN_URL;
    const {labels, affiliate, redeemVoucher} = lnFromStore?.activelang;
    const { search } = useLocation();
    const code = new URLSearchParams(search).get("code");
    const [loading, setLoading] = useState(false)

    const {refetch} = useQuery({
        queryFn: ()=> {
            setLoading(true);
          return googleLoginAPI(code);
        },
        onSuccess: (data) => {
            setLoading(false);
          navigate('/') ;dispatch(login(data))
        },
        onError: (o) => {
            setLoading(false);
            showToast("error",o?.message)
        },
        queryKey: ['googleLogin'], 
        enabled: false
    });

    const googleLoginHandler = useCallback(async () => {
        refetch()
    }, [refetch])

    useEffect(() => {
        if(code) {
            navigate(location.pathname)
            googleLoginHandler()
        }
    }, [code, googleLoginHandler, location.pathname, navigate])
  

    // For Responsive
    const [isVisible, setIsVisible] = useState(window.innerWidth >= 961);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 960);


    useEffect(() => {
        const handleResize = () => {
          setIsVisible(window.innerWidth >= 961);
          setIsMobile(window.innerWidth <= 960);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    

    const { isAuthenticated, userData } = useSelector(state => state.auth)
    const languageAssert = useSelector(state => state.language)
    const dispatch = useDispatch();
    
    const [visible, setVisible] = useState(false);

    const languages = [
        { name: 'English', code: 'EN' },
        { name: 'Spanish', code: 'ES' },
        { name: 'Portuguese', code: 'PT' }
    ];

    let menuItems = [
        { id:'plans-link', label: labels?.LBLN0313,command:()=>{ navigate('/#plans') }},
        { id:'about-link', label: labels?.LBLN0084,command:()=>{ navigate('/#aboutus') }},
        { id:'features-link', label: labels?.LBLN0169,command:()=>{ navigate('/#features') }},
        { id:'faq-link', label: labels?.LBLN0083, command:()=>{ navigate('/#faq') }},
        { id:'contactus-link', label: labels?.LBLN0170,command:()=>{ navigate('/#contact') }},
    ];
    if (isMobile) {
        menuItems.push({ id:'redeemvoucher-link', label: redeemVoucher?.VOUC001,command:()=>{ navigate('/redeem-voucher') }},)
    } 

    const userMenuList = [
        { label: labels?.LBLN0172, icon: <PR.Image src={my_plans} />, command:()=> navigate('/my-plans',{state:0}), className: (pathname === '/my-plans') ? 'p-menuitem-active' : '', },
        { label: labels?.LBLN0080, icon: <PR.Image src={my_account} />, command:()=> navigate('/my-account'), className: (pathname === '/my-account') ? 'p-menuitem-active' : '', },
        { label: labels?.LBLN0171, icon: <PR.Image src={logout} />, command:() => { setVisible(true) } }
    ];

    if (isMobile && isAuthenticated) {
        userMenuList.splice(2, 0, { label: labels?.LBLN0173, icon: <PR.Image src={notifications} />, command:()=> navigate('/notifications'), className: (pathname === '/notifications') ? 'p-menuitem-active' : '', });
    }
    
    if(userData?.signupType !== 'google' && userData?.signupType !== 'apple'){
        userMenuList.splice(2,0,{ label: labels?.LBLN0223, icon: <PR.Image src={change_password} />, command:()=> navigate('/change-password'), className: (pathname === '/change-password') ? 'p-menuitem-active' : '', })
    }

    const changeLanguage = useCallback((lang) => {
        const selectedLanguage = lang.value.code.toLowerCase();
        i18n.changeLanguage(selectedLanguage);
    }, [i18n]);

    const logoutHandler = () => {
        dispatch(logoutAction());
        setVisible(false);
        navigate('/');
    }
    useEffect(() => {
        if(!!languageAssert[language]){
            dispatch(setLanguageStore(languageAssert[language])) 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, dispatch])

    useEffect(() => {
      if(afid) {
          dispatch(storeAfid(afid));
          navigate(location.pathname);
      }
    }, [afid, dispatch, location.pathname, navigate])
    
    useQuery(['home',language],getlanguageAPI.bind(null,language,"home-new"),{
        enabled:!lnFromStore[language],
        onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
    });
    useQuery(['labels',language],getlanguageAPI.bind(null,language,"labels-new"),{
        enabled:!lnFromStore[language],
        onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
    });
    useQuery(['messageCatalog',language],getlanguageAPI.bind(null,language,"messageCatalog"),{
        enabled:!lnFromStore[language],
        onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
    });
    useQuery(['validations',language],getlanguageAPI.bind(null,language,"validations-new"),{
        enabled:!lnFromStore[language],
        onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
    });
    useQuery(['myAccount',language],getlanguageAPI.bind(null,language,"myAccount-new"),{
        enabled:!lnFromStore[language],
        onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
    });
    useQuery(['redeemVoucher',language],getlanguageAPI.bind(null,language,"redeemVoucher"),{
        enabled:!lnFromStore[language],
        onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
    });
    useQuery(['affiliate',language],getlanguageAPI.bind(null,language,"affiliate-new"),{
        enabled:!lnFromStore[language],
        onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
    });
    
    const start = (
        <div className="header-left">
            <Link to="/"><PR.Image src={getAssets.logo.logo_dark} alt="data2go logo" className="logo-img hide-on-mobile" /></Link>
            <Link to="/"><PR.Image src={getAssets.logo.logo_icon} alt="data2go logo" className="logo-img show-on-mobile" /></Link>
        </div>
    );

    const end = (
        <div className={
                pathname === "/affiliate" || pathname === "/affiliate-signup" 
                ? 'header-right flex align-items-center gap-3 affiliate-header-right' 
                : 'header-right flex align-items-center gap-3'
            }>
            <div className="card">
                <PR.Dropdown
                  value={languages?.filter(e=> e.code.toLowerCase()===language)?.[0]}
                    onChange={changeLanguage}
                    options={languages}
                    optionLabel="name"
                    placeholder="English"
                    className="language-dropdown"
                    panelClassName="language-panel"
                />
            </div>
            {(isVisible && pathname !== "/affiliate") && (
            <PR.Button onClick={navigate.bind(null, '/redeem-voucher')} label={redeemVoucher?.VOUC001} className="login-button" />
            )}

            {pathname !== "/affiliate" && pathname !== "/affiliate-signup" ? <>  {isAuthenticated 
                    ? <div className="card flex justify-content-center">
                        {isVisible && (
                        <PR.Button className="my-account-buttons" onClick={navigate.bind(null, '/notifications')}>
                            <PR.Image src={notifications} alt="Notifications" />
                        </PR.Button>
                        )}
                        <PR.Menu model={userMenuList} popup ref={userMenu} />
                        <div className="flex align-items-center" onClick={(e) => userMenu.current.toggle(e)}>
                            <PR.Button className="my-account-buttons user-button">
                                <PR.Image src={user_icon} alt="Logged-in User" />
                            </PR.Button>
                            <i className="pi pi-chevron-down"></i>
                        </div>
                    </div>
                    :
                   pathname === "/signin"?   <PR.Button onClick={navigate.bind(null, '/signup')} label={labels?.LBLN0038} className="login-button" /> : <PR.Button onClick={navigate.bind(null, '/signin')} label={labels?.LBLN0068} className="login-button" />
                }</>:null}
                        {pathname === "/affiliate" || pathname === "/affiliate-signup"  ?      
                        <Link target='_blank' to={affiliateURL}>
                        <PR.Button label={affiliate?.AFFN0028} className="view-button" />
                        </Link>
                :null}
        </div>
    );

    return (
        <>
        {loading && <div className="full-window-loading">
            <p>
                <i className="pi pi-spin pi-spinner mr-2"></i>
                {labels.LBLN0266}
            </p>
            </div>}
            <section className="header-menu-section">
                <div className="grid grid-nogutter">
                    <div className="col-12">
                        <PR.Menubar model={pathname !== "/affiliate" && pathname !== "/affiliate-signup" ? menuItems:[]} start={start} end={end} />
                    </div>
                </div>
            </section>
            <PR.Dialog visible={visible} onHide={() => { if (!visible) return; setVisible(false); }} className="logout-dialog">
                <div className="flex align-items-center gap-3">
                    <div className="logout-icon"><PR.Image src={logout} /></div>
                    <h2>{labels?.LBLN0171}</h2>
                </div>
                <div className="content">
                    <p>{labels?.LBLN0174}</p>
                    <div className="flex align-items-center gap-3">
                        <PR.Button label={labels?.LBLN0175} className="logout-buttons cancel" onClick={() => setVisible(false)} />
                        <PR.Button label={labels?.LBLN0171} className="logout-buttons" onClick={logoutHandler} />
                    </div>
                </div>
            </PR.Dialog>
        </>
    );
};

export default HeaderMenu;
