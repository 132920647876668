import React from "react";
import * as PR from "../../prime-modules/index";
import getAssets from "../../assets/data/brandingAssets.json"
import { envConfig } from "../../constants";

let {google_icon} = getAssets.icons

const GoogleLogin = ({labels}) => {

  const login = () => {
    const scope = 'profile email https://www.googleapis.com/auth/user.phonenumbers.read'
    window.location.href = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${envConfig.REACT_APP_GOOGLE_AUTH_CLIENT_KEY}&redirect_uri=${envConfig.REACT_APP_GOOGLE_CALLBACK_URL}&scope=${encodeURIComponent(scope)}`
  }

  return  <div className="col-12 md:col-6">
    <PR.Button
      onClick={login}
      type="button"
      className="social-button"
    >
      <span className="button-label">{labels?.LBLN0072}</span>
      <PR.Image src={google_icon} alt="Google" />
    </PR.Button>
  </div>
};

export default GoogleLogin;
