import { PaymentElement, useCustomCheckout } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { useToast } from "../../context/ToastContext";
import * as PR from "../../prime-modules/index";
import { useEffect, useState } from "react";
import { CheckCompatible } from "./helper";

const CardPayment = ({checked, setChecked, dispalyPrice, userInfo}) => {
    const { labels} = useSelector(state=>state.language?.activelang);
    const {showToast} = useToast()
    const { confirm } = useCustomCheckout();

    const checkout = useCustomCheckout()
    const [paymentProcessing, setPaymentProcessing] = useState(false);
    
useEffect(() => {
  if (userInfo?.email && !checkout?.email) {
    checkout.updateEmail(userInfo?.email);
  }
}, [checkout, userInfo?.email]);

    const payNowHandler = async () => {
      setPaymentProcessing(true);      
    confirm().then((result) => {
      setPaymentProcessing(false);      
          if (result.error) {
            showToast("error", result?.error?.message)
          } 
        })
    };

    return (
      <div className="card-section">
        <PaymentElement
          options={{
            layout: 'accordion',
          }}
        />
        <div className="mt-4 mb-2">
          <CheckCompatible
            checked={checked}
            setChecked={setChecked}
            id="cardPayment"
            labels={labels}
          />
        </div>
        <PR.Button
          onClick={payNowHandler}
          disabled={!checked.cardPayment || paymentProcessing}
          label={`${labels?.LBLN0103} $${dispalyPrice}`}
          className="pay-button mt-4"
          type="submit"
        />
      </div>
    );
}
 
export default CardPayment;


