import React, { useCallback, useEffect, useRef, useState } from "react";
import Layout from "../../components/layout/Layout";
import "./Payment.scss";
import * as PR from "../../prime-modules/index";
import PaymentTab from "./PaymentTab";
import { getCheckoutSessionAPI, paymentMethodAPI, validateCouponAPI } from "../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { v4 as uuid } from "uuid";
import { postData, priceFormating, v2PaymentRequestData } from "./helper";
import FreePayment from "./FreePayment";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../context/ToastContext";
import { rcTransformData } from "../../utils/reuse";
import { setRandomIdForPayment } from "../../store/auth.slice";
import NetworksPopup from "../../components/bundle/NetworksPopup";
import { LabelIcon } from "../../components/bundle/BundlePopupDetailsList";

const Payment = () => {
    const dispatch = useDispatch();
    const {showToast, showMessage} = useToast()
    const {validations, labels, myAccount: account} = useSelector(state=>state.language?.activelang);
    const {isAuthenticated:isAuth} = useSelector((state) => state.auth);
    const [promoCode, setPromoCode] = useState("");
    const { userData: userInfo } = useSelector((state) => state.auth);
    const [visible, setVisible] = useState(false);
    const [networkDialog, setNetworkDialog] = useState(false);
    const [checked,setChecked] = useState({});
    const { state } = useLocation();
    const navigate = useNavigate();
    const couponRef = useRef(null);
    const randomId = useSelector((state) => state.auth.randomId);
    
    const formikRef = useRef(null);
    useEffect(() => {
        if (!state?.bundle) navigate('/') 
    }, [state?.bundle, navigate]);

    const paymentResponse = payRes => {
        if (payRes.status === "succeeded" || payRes.status === "in_progress") {
            window.history.replaceState({}, '')
            navigate('/payment-success', { state: { ...payRes } })
        } else {
            showToast("error", payRes.error)
        }
    }

    // API Requests Start //
    const { mutate: makePaymentMutate, isLoading: makePaymentLoading } = useMutation(paymentMethodAPI, {
        onSuccess: paymentResponse,
        onError: er => {
          showToast("error", er?.message)
        }
    });

    const makePayment = useCallback((data) => {
        makePaymentMutate(data)
    }, [makePaymentMutate])

    const { mutate: validateCoupon } = useMutation(validateCouponAPI, {
        onSuccess: (success, variables) => { 
          let promoCodeData = {}
          if(variables?.promoCode === "" && success?.bundlePrice !== success?.discountedPrice) {
            promoCodeData = { discount : {...success}}
          } else {
            promoCodeData = {success, promoCode: couponRef?.current?.value}
          }
        navigate("/payment", {replace: true, state: {...state, ...promoCodeData, affiliatePromoCodeCall: true}})
          if (success?.bundlePrice !== success?.discountedPrice) {
            if (state?.clientSecret) {
              v2PayHandler(state?.userData, true, promoCodeData);
            }
          }
         },
        onError: er => { 
        navigate("/payment", {replace: true, state: {...state, affiliatePromoCodeCall: true}})
          setPromoCode({ error: showMessage(er.message) }) 
        }
    })
    // API Requests Ends //
    
    const { price, name, description, dataAmount, duration, speed, roamingEnabled, countries, iccid } = state?.bundle ?? {};
    const { amount, total, discount, grandTotal } = priceFormating({ price, ...(state?.success ?? state?.discount ?? {}) });

    const paymentHandler = useCallback(billingInfo => {
        const op = amount === 0 ? {} : { originalPrice: price }
        const promoCodeDiscount = !!state?.success ? { ...op, promoCode: couponRef.current.value } : {};

        makePayment({
            ...postData,
            ...billingInfo,
            iccid: iccid ?? '',
            orderSummary: {
                dataPlans: [{ name, description, quantity: 1, price, speed, duration, dataAmount, ...promoCodeDiscount }],
                totalOrderAmount: price
            },
            randomId :  randomId ? randomId :  uuid()
        })

    }, [amount, price, state?.success, makePayment, iccid, name, description, speed, duration, dataAmount, randomId])

    const { mutate: createCheckoutHandler, isLoading: clientSecretLoading } = useMutation(
        getCheckoutSessionAPI,
        {
          onSuccess: (res, variables) => {
            const { sessionClientSecret: clientSecret, checkoutSessionID } = res;
            const {success, promoCode} = variables;
            navigate("/payment", {replace: true, state: {...state, clientSecret, success, promoCode, checkoutSessionID, userData: {...formikRef?.current?.values}}})
          },
          onError: (er) => {
            showToast("error", er?.message);
          },
        }
      );

      const v2PayHandler = useCallback((userData, promoCode, promoCodeData) => {
        
        const op = amount === 0 ? {} : { originalPrice: price }
        const promoCodeDiscount = (!!state?.success || promoCode) ? { ...op, promoCode: couponRef?.current?.value } : {};

            const customerDetails = {
                firstName: userInfo?.firstName ? userInfo?.firstName : userData?.firstName,
                lastName: "",
                email: userInfo?.email ? userInfo?.email : userData?.email,
                phone: {
                  callingCode: "",
                  localPhoneNumber: ""
                }
            }
            
            const dataPlans = [
              {
                name,
                description,
                quantity: 1,
                price,
                speed,
                duration,
                dataAmount,
                ...promoCodeDiscount
              },
            ];
            const paymentData = {
                contactInfo: customerDetails,
                orderSummary: {
                    dataPlans: dataPlans,
                    totalOrderAmount: price
                },
                paymentGateway: "Stripe",
                iccid: iccid ?? '',
                ...v2PaymentRequestData,
                ...promoCodeData
            }
            createCheckoutHandler(paymentData)
    }, [amount, createCheckoutHandler, dataAmount, description, duration, iccid, name, price, speed, state?.success, userInfo?.email, userInfo?.firstName])

    const couponHandler = useCallback((promoCode = false) => {
        const randomCheckoutID = randomId ? randomId :  uuid();
        dispatch(setRandomIdForPayment(randomCheckoutID));

        if (!!couponRef.current.value || promoCode) validateCoupon({
            bundleName: name,
            promoCode: couponRef.current.value,
            randomId: randomCheckoutID,
            iccid:iccid ?? "",
            checkoutSessionID: state?.checkoutSessionID
        })

        else setPromoCode({error: validations?.VLDN0016})
    }, [dispatch, iccid, name, randomId, state?.checkoutSessionID, validateCoupon, validations?.VLDN0016])

    useEffect(() => {
      if (isAuth && !state?.affiliatePromoCodeCall && !state?.clientSecret) {
        couponHandler(true);
      }
    }, [couponHandler, isAuth, navigate, state]);

    useEffect(() => {
      if(isAuth && !state?.clientSecret && state?.affiliatePromoCodeCall === true) {
        v2PayHandler()
      }
    }, [isAuth, state, v2PayHandler])

    if(!state?.bundle) {
      return null
    }

    return (
      <>
        <Layout>
          <section className="payment-section">
            <NetworksPopup labels={labels} setVisible={setVisible} networkDialog={networkDialog} roamingEnabled={roamingEnabled} countries={countries} setNetworkDialog={setNetworkDialog} />
            <div className="grid" style={{ position: "relative" }}>
              {makePaymentLoading && (
                <div className="loader">
                  <span>
                    <i className="pi pi-spin pi-spinner"></i>
                    {account?.ACTN0037}
                  </span>
                </div>
              )}
              <div className="col-12 md:col-4">
                <div className="summary-block">
                  <h2>{labels?.LBLN0267}</h2>
                  <ul>
                    <li>{description.split("(")[0]}</li>
                    <li>{speed?.join("-")}</li>
                    <li>
                      {duration}{" "}
                      {duration > 1 ? labels?.LBLN0254 : labels?.LBLN0252}{" "}
                      {labels?.LBLN0255}
                    </li>
                    <li>
                      <LabelIcon id={labels?.LBLN0106} onClick={setVisible} geog_type={(roamingEnabled.length === 0 || roamingEnabled.length === 1)? "Local" : "Regional"} data={(roamingEnabled.length === 0 || roamingEnabled.length === 1)? countries : roamingEnabled} countShow={true} />
                      </li>
                    <li>
                        
                      {roamingEnabled.length === 0
                        ? <LabelIcon id={labels?.LBLN0292} onClick={setNetworkDialog} data={countries?.[0]?.networks} countShow={true} />
                        : <LabelIcon id={`${labels?.LBLN0029} ${labels?.LBLN0030}`} countShow={false} onClick={setNetworkDialog} data={(roamingEnabled.length === 0 || roamingEnabled.length === 1)? countries?.[0]?.networks : roamingEnabled} />}
                      </li>                 
                  </ul>
                </div>
                <div className="summary-block mt-5">
                  <h2>{labels?.LBLN0269}</h2>
                  {!!state.promoCode ? (
                    <div className="applied-coupon">
                      <span>
                        <span
                          style={{
                            color: "lightgreen",
                            fontSize: 12,
                            textTransform: "uppercase",
                          }}
                        >
                          {labels?.LBLN0098}:
                        </span>{" "}
                        <strong>
                          <i>{couponRef?.current?.value || state?.promoCode}</i>
                        </strong>
                      </span>
                       <i
                        className="pi pi-times applied-coupon-close"
                        onClick={() => {
                          const promoCodeData = {success: null, promoCode: null};
                          navigate("/payment", {replace: true, state: {...state, ...promoCodeData, affiliatePromoCodeCall: true}})
                          setPromoCode({});
                          if (state?.userData?.email) {
                            v2PayHandler(state?.userData, false, {success: null, promoCode: null})
                          }
                        }}
                      ></i> 
                    </div>
                  ) : (
                      <div className="coupon-field">
                        <PR.InputText
                          ref={couponRef}
                          placeholder={labels?.LBLN0270}
                        />
                        <PR.Button
                          label={labels?.LBLN0271}
                          onClick={() => couponHandler(false)}
                          className="apply-button"
                        />
                        {promoCode.error && (
                          <span className="err-msg">{promoCode.error}</span>
                        )}
                      </div>
                  )}
                  <div className="order">
                    <span>{labels?.LBLN0272}</span>
                    <span>${total}</span>
                  </div>
                  <div className="order">
                    <span>{labels?.LBLN0101} {!state?.promoCode && <span style={{color: 'lightgreen'}}>{state?.discount?.discountValue ? `(${state?.discount?.discountValue})` : ""}</span>}</span>
                    <span>${discount}</span>
                  </div>
                  <div className="order">
                    <span>{labels?.LBLN0273}</span>
                    <span className="font-bold">${grandTotal}</span>
                  </div>
                </div>
              </div>
              <div className="col-12 md:col-8">
                  <div className="payment-methods">
                    {amount === 0 ? (
                      <FreePayment
                        onConfirm={paymentHandler}
                        checked={checked}
                        setChecked={setChecked}
                      />
                    ) : (
                      <PaymentTab
                        price={price}
                        checked={checked}
                        setChecked={setChecked}
                        onPayment={paymentHandler}
                        dispalyPrice={grandTotal}
                        promoCode={promoCode}
                        paymentResponse={paymentResponse}
                        stateBundle={state?.bundle}
                        formikRef={formikRef}
                        v2PayHandler={v2PayHandler}
                        clientSecretLoading={clientSecretLoading}
                        paypalPaymentLoading={makePaymentLoading}
                        state={state}
                      />
                    )}
                  </div>
              </div>
            </div>
          </section>

          <PR.Dialog
            header={labels?.LBLN0107}
            visible={visible}
            blockScroll={true}
            draggable={false}
            onHide={() => {
              if (!visible) return;
              setVisible(false);
            }}
            className="roaming-countries-dialog"
          >
            <div className="content">
              <ul style={{ columnCount: 3 }}>
                {rcTransformData(roamingEnabled).map((el, i) => (
                  <li key={i}>{el?.split("(")[0]}</li>
                ))}
              </ul>
            </div>
          </PR.Dialog>
        </Layout>
      </>
    );
};

export default Payment;
