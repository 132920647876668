import React, { useRef, useState } from 'react'
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import * as PR from "../../prime-modules/index";
import { contactUsValidationSchema } from '../../utils/schema';
import Input from '../form/Input';
import { contactus } from '../../services/api';
import { ALL_COUNTRY } from '../../utils/localstore';
import { useToast } from '../../context/ToastContext';
import getAssets from "../../assets/data/brandingAssets.json"
import { getImageSrc } from '../../utils/reuse';
import { Link } from 'react-router-dom';


function ContactUs({labels, home}) {
    let get_in_touch = getImageSrc(home?.HOMEN0085, getAssets.home.get_in_touch)
    const {userData : userInfo} = useSelector(state=> state.auth);
    const {showToast} = useToast()
    const [checked, setChecked] = useState(false);
    const { ipcountry } = useSelector(state => state.country);
    const allCountries = JSON.parse(localStorage.getItem(ALL_COUNTRY))
    const phone = !!userInfo?.phone?.callingCode ? userInfo?.phone?.callingCode + userInfo?.phone?.localPhoneNumber : allCountries?.filter(e=> ipcountry===e.iso)?.[0]?.callingCode??allCountries?.[0]?.callingCode 
    const formikRef = useRef(null)
    
    const initialValues = {
        firstName: userInfo?.firstName??'',
        lastName: userInfo?.lastName??'',
        email: userInfo?.email??'',
        phone: !!phone ? phone : '',
        body: '',
    }
    

    const {mutate, isLoading} = useMutation(contactus,{
        onSuccess:()=> {
            showToast("success",labels?.LBLN0281)
            formikRef.current.resetForm();
        },
        onError:(o)=> showToast("error",o?.message)
    })

    const submitHanlder  = value => {
       const [callingCode, localPhoneNumber] = `+${value?.phone}`?.split('-');
       mutate({ ...value, type:"contact", phone: { callingCode, localPhoneNumber }});
    }
  return (
    <section className="get-in-touch-section">
    <div className="card" id="contact">
        <div className='text-center'>
            <h2>{home?.HOMEN0049}</h2>
            <p>{home?.HOMEN0061}</p>
        </div>
        <div className='grid custom-margin form-block'>
            <div className="col-12 lg:col-6">
                <PR.Image src={get_in_touch} alt="Getintouch" className='get-in-touch-image'/>
            </div>
            <div className="col-12 lg:col-6">
                <div className="form-wrapper">
                    {/* Form section starts here */}
                    <Formik innerRef={formikRef} validateOnMount={true} initialValues={initialValues} validationSchema={contactUsValidationSchema} onSubmit={submitHanlder} enableReinitialize={true}>
                     {({isValid})=>(
                           <Form autoComplete="off" className="form-block" >
                           <div className='grid'>
                               <div className="col-12 md:col-6">
                                   <Input id={'firstName'} name={'firstName'} label={labels?.LBLN0001} type={'text'} aria-describedby={'f-name'} placeholder={labels?.LBLN0207} />
                               </div>
                               <div className="col-12 md:col-6">
                                   <Input id={'lastName'} name={'lastName'} label={labels?.LBLN0002}  type={'text'} aria-describedby={'l-name'} placeholder={labels?.LBLN0208} />
                               </div>
                               <div className="col-12 md:col-12">
                                   <Input id={'email'} name={'email'} label={labels?.LBLN0037} type={'email'} aria-describedby={'email'} placeholder={labels?.LBLN0209} />
                               </div>
                               <div className="col-12 md:col-12">
                                   <Input id={'phone'} name={'phone'} label={labels?.LBLN0011} type={'text'} aria-describedby={'email'} placeholder={labels?.LBLN0213} />    
                               </div>
                               <div className="col-12 md:col-12">
                               <Input id={'body'} name={'body'} label={labels?.LBLN0012} rows={4} cols={30}  type={'message'} aria-describedby={'message'} placeholder={labels?.LBLN0210} />
                               </div>
                               <div className="col-12 md:col-12">
                                   <div className="flex align-items-top gap-3 mb-3">
                                       <PR.Checkbox onChange={e => setChecked(e.checked)} checked={checked}></PR.Checkbox>
                                       <label htmlFor="agree" className='agree-label'>{labels?.LBLN0211} <Link to={'/privacy-policy'}>{labels?.LBLN0299}</Link></label> 
                                   </div>
                               </div>
                               <div className="col-12">
                                   <PR.Button disabled={!isValid || !checked || isLoading} label={home?.HOMEN0062} type='submit' className="send-button w-12"/>
                               </div>
                           </div>
                       </Form>
                     )}
                    </Formik>
                    {/* Form section ends here */}
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default ContactUs