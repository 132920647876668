import React,{useRef} from "react";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import * as PR from "../../prime-modules/index";
import Layout from "../../components/layout/Layout";
import Input from "../../components/form/Input";
import { changePasswordValidationSchema } from "../../utils/schema";
import { changeUserPassword } from "../../services/api";
import { logout } from "../../store/auth.slice";
import { useToast } from "../../context/ToastContext";

const ChangePassword = () => {
    const {showMessage, showToast} = useToast()
    const { userId } = useSelector((state) => state.auth.userData);
    const {labels} = useSelector(state=>state.language?.activelang);
    const toast = useRef();
    const dispatch = useDispatch()
    const { mutate, error } = useMutation(changeUserPassword, {
      onSuccess: () => {
        showToast("success",labels?.LBLN0222)
        setTimeout(()=>{
          dispatch(logout());
        },1000)
      },
    });

    const handleSubmit = (value) => {
        const obj = {
            newPassword:value.confirmPassword,
            password: value.currentPassword,
            userId
        }
     mutate({ ...obj});
    };
    
    return (
      <>
        <Layout>
          <section className="signup-section">
            <div className="grid">
              <div className="col-12 text-center">
                <h1>{labels?.LBLN0223}</h1>
                <p>{labels?.LBLN0238}</p>
                <div className="signup-wrapper">
                  {/* Form section starts here */}
                  <Formik
                    initialValues={{
                      currentPassword: "",
                      password: "",
                      confirmPassword: ''
                    }}
                    validationSchema={changePasswordValidationSchema}
                    onSubmit={handleSubmit}
                  >
                  <Form autoComplete="off" className="form-block">
                    <div className="grid">
                      <div className="col-12 md:col-12">
                          <Input
                            feedback={false}
                            label={labels?.LBLN0239}
                            name={"currentPassword"}
                            type={"password"}
                            aria-describedby={"currentPassword"}
                            toggleMask
                            placeholder={labels?.LBLN0240}
                          />
                      </div>
                      <div className="col-12 md:col-12">
                          <Input
                            feedback={false}
                            label={labels?.LBLN0241}
                            name={"password"}
                            type={"password"}
                            aria-describedby={"currentPassword"}
                            toggleMask
                            placeholder={labels?.LBLN0294}
                          />
                      </div>
                      <div className="col-12 md:col-12">
                          <Input id={'confirmPassword'} name={'confirmPassword'} feedback={false} type={'password'} label={labels?.LBLN0005} aria-describedby={'confirm-password'} toggleMask placeholder={labels?.LBLN0243} />    
                      </div>
                      <div className="card">
                        {error && (
                            <span className="err-msg">
                              {showMessage(error.message)}
                            </span>
                          )}
                      </div>
                      <div className="col-12">
                        <PR.Button
                          label={labels?.LBLN0237}
                          type="submit"
                          className="signup-button"
                        />
                      </div>
                    </div>
                  </Form>
                  </Formik>
                  {/* Form section ends here */}
                </div>
              </div>
            </div>
          </section>
          <PR.Toast ref={toast} position="top-right" />
        </Layout>
      </>
    );
};

export default ChangePassword;
