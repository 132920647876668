import React from 'react'
import * as PR from "../../prime-modules/index";
import getAssets from "../../assets/data/brandingAssets.json"
import { Link } from 'react-router-dom';
import { getImageSrc } from '../../utils/reuse';

function StoreLinks({home}) {
   let mobile_screen = getImageSrc(home?.HOMEN0076, getAssets.home.mobile_screen)
   let store_badge = getImageSrc(home?.HOMEN0074, getAssets.home.store_badge)
   let play_badge = getImageSrc(home?.HOMEN0075, getAssets.home.play_badge)
  return (
    <section className="coverage-section mobile-app-section">
    <div className="coverage-container">
        <div className="grid align-items-center">
            <div className="col-12 lg:col-6">
                <div className='custom-padding'>
                    <h2>{home?.HOMEN0038}</h2>
                    <p>{home?.HOMEN0039}</p>
                    <Link to={home?.HOMEN0041} target='_blank' ><PR.Image src={store_badge} alt="App Store Badge" className='badge'/></Link>
                    <Link to={home?.HOMEN0040} target='_blank' ><PR.Image src={play_badge} alt="Google Play Store Badge" className='badge'/></Link>
                </div>
            </div>
            <div className="col-12 lg:col-6 pb-0 text-center">
                <div className='phone-circle-bg'>
                    <PR.Image src={mobile_screen} alt="Mobile application"/>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default StoreLinks