import React from 'react'
import * as PR from "../../prime-modules/index";
import getAssets from "../../assets/data/brandingAssets.json"
import { getImageSrc } from '../../utils/reuse';
import { Link } from 'react-router-dom';


function AboutUs({home}) {
    let instant_activation = getImageSrc(home?.HOMEN0077, getAssets.about_us.instant_activation)
    let attractive_rates = getImageSrc(home?.HOMEN0078, getAssets.about_us.attractive_rates)
    let global_coverage = getImageSrc(home?.HOMEN0079, getAssets.about_us.global_coverage)

    const splitText = ()=> {
        let arraySplitText =[]
        if(!!home?.HOMEN0052){
            let text = "reliable, secure, and flexible"
            const regex = new RegExp(`(${text})`);
            console.log(regex);
            
            arraySplitText = home?.HOMEN0052.split(regex);
        }
        return <h2>{arraySplitText[0]} <span>{arraySplitText[1]}</span> {arraySplitText[2]}</h2>
    }

  return (
    <section  className="whoweare-section">
    <div id="aboutus" className="whoweare-container">
        <div className="grid">
            <div className="col-12 text-center">
                <div className='text-block'>
                    {/* Removed as per Chandru's instructions. */}
                    {/* <h3 >{ABN0001}</h3> */}
                    {/* <h2>{home?.HOMEN0052} <span>{home?.ABN0004}</span></h2> */}
                   { splitText()}
                </div>
            </div>
        </div>
        <div id="features" className="grid">
            <div className="col-12 lg:col-4">
                <div className='box'>
                    <div className='icon'>
                        <PR.Image src={instant_activation} alt="Instant Activation"/>
                    </div>
                    <Link to="#activation"><h2>{home?.HOMEN0053}</h2></Link>
                    <p>{home?.HOMEN0054}</p>
                </div>
            </div>
            <div className="col-12 lg:col-4">
                <div className='box'>
                    <div className='icon'>
                        <PR.Image src={attractive_rates} alt="Instant Activation"/>
                    </div>
                    <Link to="#plans"><h2>{home?.HOMEN0055}</h2></Link>
                    <p>{home?.HOMEN0056}</p>
                </div>
            </div>
            <div className="col-12 lg:col-4">
                <div className='box'>
                    <div className='icon'>
                        <PR.Image src={global_coverage} alt="Instant Activation"/>
                    </div>
                    <Link to="#coverage"><h2>{home?.HOMEN0057}</h2></Link>
                    <p>{home?.HOMEN0058}</p>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default AboutUs