import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import moment from "moment";

import { getOrderDetails } from "../../services/api";
import { checkDataSize, modifyDate, setPrice } from "../../utils/reuse";
import Layout from "../../components/layout/Layout";
import * as PR from "../../prime-modules/index";
import { useToast } from "../../context/ToastContext";
import getAssets from "../../assets/data/brandingAssets.json"

let { warning_icon } = getAssets.icons

const BundleDetails = () => {
  const { userId } = useSelector((state) => state.auth.userData);
  const {myAccount:account, labels} = useSelector(state=>state.language?.activelang);
  const {showToast} = useToast()
  const location = useLocation();
  const bundle = location.state;
  const { mutate, data, isLoading } = useMutation(getOrderDetails, {
    onError: (o) => showToast("error",o?.message)

  });

  useEffect(() => {
    let data = {
      userId,
      orderId: bundle?.orderId,
    };
    mutate(data);
    return () => {};
  }, [mutate, userId, bundle?.orderId]);
  const BundleExpiry = () => {
    let expiryDate = moment(bundle?.bundleEndTime).format("DD/MM/YYYY");
    let lastDate = new Date(expiryDate.split("/").reverse().join("-"));
    return (
      expiryDate === "01/01/0001" ? null: <div className="warning-msg flex gap-2 align-items-center">
        <PR.Image src={warning_icon} alt="Warning" />
        <p>
          {bundle?.state === "expired" ? account?.ACTN0119:`${account?.ACTN0120}
          ${Math.ceil((lastDate - new Date()) / (1000 * 60 * 60 * 24))} ${labels?.LBLN0018}
          ${expiryDate}`}
        </p>
      </div>
    );
  };
  return (
    <Layout>
        <section className="signup-section myplans-section">
          <div className="grid">
            <div className="col-12 text-center">
              <h1>{labels?.LBLN0172}</h1>
              <p>{account?.ACTN0099}</p>
              {isLoading || data === undefined ? (
              <div className="loading-div text-center mt-8">
                <i
                  className="pi pi-spin pi-spinner"
                      style={{ fontSize: "2rem", color: "white" }}
                    ></i>
                  </div>
                ) : (
              <div className="plans-grid-section">
                <div className="bundle-details-section">
                  <div className="grid bundles-list">
                    <div className="col-12 md:col-12 lg:col-4">
                      <div className="sticky-block">
                        <p className="heading-p">{labels?.LBLN0289}</p>
                        <div className="bundle">
                          <div className="flex align-items-center gap-3">
                            <div className="dot">
                              <span className={bundle?.state === "active" || bundle?.state === "expired" ? bundle?.state: "pending" }></span>
                            </div>
                            <div>
                              <h2>{bundle?.tag} </h2>
                              <p>ICCID: {data?.iccid}</p>
                            </div>
                          </div>

                          <div className="expiry-column">
                            <p></p>
                            <div className="card mt-1"></div>
                          </div>
                          <div className="flex align-items-center justify-content-between mt-4">
                            <h4>
                              {bundle?.isUnlimited ? labels?.LBLN0167 : `${`${checkDataSize(bundle?.remainingData)}`} / ${checkDataSize(bundle?.totalData)} ${account?.ACTN0098}`}
                            </h4>
                          </div>
                          <div className="card mt-4">
                            <PR.ProgressBar
                              value={
                                bundle?.totalData
                                  ? ((bundle?.remainingData) /
                                      bundle?.totalData) *
                                    100
                                  : 0
                              }
                              className="progress-bar"
                            ></PR.ProgressBar>
                          </div>
                          {BundleExpiry()}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 md:col-12 lg:col-8">
                      <p className="heading-p">{labels?.LBLN0290}</p>
                      <div className="order-details-block">
                        <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0024}</li>
                          <li>{data?.orderId}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0021}</li>
                          <li>{modifyDate(data?.createdTs)}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0116}</li>
                          <li>{setPrice(data?.priceBeforeDiscount)}</li>
                        </ul>
                        {!!data?.promoCode && !!data?.discountValue &&
                          <>
                            <ul className="flex align-items-center justify-content-between">
                              <li>{account?.ACTN0117}</li>
                              <li>{setPrice(data?.discount)}</li>
                            </ul>
                            <ul className="flex align-items-center justify-content-between">
                              <li>{account?.ACTN0118}</li>
                              <li>{setPrice(data?.totalOrderAmount)}</li>
                            </ul>
                          </>
                        }
                        {/* <ul className="flex align-items-center justify-content-between">
                          <li>Receipt</li>
                          <li>
                            <PR.Button
                              label="Download"
                              className="download-button"
                              icon="pi pi-download"
                            />
                          </li>
                        </ul> */}
                      </div>

                      <p className="heading-p mt-5">{labels?.LBLN0291}</p>
                      <div className="order-details-block">
                        <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0006}</li>
                          <li>{data?.paymentGateway}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0004}</li>
                          <li>{data?.transactionId}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0107}</li>
                          <li>
                            <span className="completed">
                              {data?.transactionStatus}
                            </span>
                          </li>
                        </ul>
                      </div>

                      <p className="heading-p mt-5">{account?.ACTN0108}</p>
                      <div className="order-details-block">
                        <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0015}</li>
                          <li>
                            {data?.firstName} {data?.lastName}
                          </li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0014}</li>
                          <li>{data?.email}</li>
                        </ul>
                        {data?.phone?.localPhoneNumber && <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0109}</li>
                          <li>
                            {data?.phone?.callingCode +
                              " " +
                              data?.phone?.localPhoneNumber}
                          </li>
                        </ul>}
                      </div>

                      <p className="heading-p mt-5">{account?.ACTN0110}</p>
                      <div className="order-details-block">
                        <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0111}</li>
                          <li>{bundle?.isUnlimited ? labels?.LBLN0167 : checkDataSize(bundle?.totalData)}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0105}</li>
                          <li>{bundle?.description?.split('-')[0]}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                         <li>{labels?.LBLN0097}</li>
                         <li>{setPrice(data?.dataPlans[0]?.price)}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0112}</li>
                          <li>{bundle?.country?.split("(")[0]}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0113}</li>
                          <li>{account?.ACTN0115}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>{account?.ACTN0114}</li>
                          <li>e-SIM</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                )}
            </div>
          </div>
        </section>
    </Layout>
  );
};

export default BundleDetails;
