import { useSelector } from 'react-redux';
import './index.scss'
import * as PR from "../../prime-modules/index";
import HeaderMenu from '../../components/layout/HeaderMenu';
import { Form, Formik } from 'formik';
import Input from '../../components/form/Input';
import { affiliateSignUpSchema } from '../../utils/schema';
import { useRef } from 'react';
import { useMutation } from 'react-query';
import { affiliateSignup } from '../../services/api';
import { useToast } from '../../context/ToastContext';


const PARTNER_PROGRAM  ={
    firstName: '',
    lastName: '',
    email: '',
    body: 'AFFILIATE SIGNUP',
    phone: {
        localPhoneNumber: '',
        callingCode: ''
    }
}


const AffiliateSignUp = () =>{
    const {affiliate} = useSelector(state=>state.language?.activelang);
    
    const formikRef = useRef();
    const {showToast} = useToast()


    const { mutate } = useMutation( affiliateSignup, {
        onSuccess:()=>{
            showToast('success', "CNTMSG-04")
            formikRef.current.resetForm();
        },
        onError: () =>{
            showToast('error', "Error")
        }
    });

    const submitHandler = ({email}) => {
        mutate({...PARTNER_PROGRAM, firstName:email.split('@')?.[0],  email});
    }
    return (
        <>
            <div className='main-section'>
                <HeaderMenu />
            </div>
            <div className="getintouch-section">
                <div className='container'>
                    <div className='grid custom-margin'>
                        <div className="col-12 text-center">
                            <h2 style={{margin:'0 -25px'}}>{affiliate?.AFFN0030}</h2>
                            <h2 style={{margin:'0 -25px'}}>{affiliate?.AFFN0031} <span>{affiliate?.AFFN0032}</span></h2>
                            <Formik innerRef={formikRef} validationSchema={affiliateSignUpSchema} initialValues={{email:''}} onSubmit={submitHandler} >
                                <Form autoComplete="off" className="form-block" >
                                    <h3>{affiliate?.AFFN0029}</h3>
                                    <Input name="email" id="email" aria-describedby="email" placeholder={affiliate?.AFFN0033} />
                                    <PR.Button label={affiliate?.AFFN0034} type='submit' className="goto-button"/>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AffiliateSignUp