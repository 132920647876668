import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from "../pages/home/Home";
import Signup from '../auth/signup/Signup';
import Signin from '../auth/signin/Signin';
import ForgotPassword from '../auth/forgot-password/ForgotPassword';
import ResetPassword from '../auth/reset-password/ResetPassword'
import MyAccount from '../auth/my-account/MyAccount';
import ChangePassword from '../auth/change-password/ChangePassword';
import VerifyEmail from '../auth/verify-email/VerifyEmail';
import MyPlans from '../pages/my-plans/MyPlans';
import { PrivateRoute, PublicRoute } from './customRoute';
import NotFoundPage from '../pages/404';
import FAQ from '../pages/help/Faq';
import AffiliateSignUp from '../pages/affiliateSignUp/AffiliateSignUp';

import TermsAndCondition from '../pages/help/TermsAndCondition';
import PrivacyPolicy from '../pages/help/PrivacyPolicy';
import CookiePolicy from '../pages/help/CookiePolicy';
import CompatibleDevices from '../pages/compatible-devices/CompatibleDevices';
import BundleDetails from '../pages/my-plans/BundleDetails';
import TopUp from '../pages/my-plans/TopUp';
import Notifications from '../pages/notifications/Notifications';
import Affiliate from '../pages/affiliate/Affiliate'
import { ALL_COUNTRY } from '../utils/localstore';
import { getAllcountry, getCountries } from '../services/api';
import { useQuery } from "react-query";
import { useDispatch, useSelector } from 'react-redux';
import { setCountry } from '../store/country.slice';
import RedeemVoucher from '../pages/redeem-voucher/RedeemVoucher';
import WebPaypal from '../pages/payment/WebPaypal';
import Payment from '../pages/payment/Payment';
import PaymentSuccess from '../pages/payment-success/PaymentSuccess';

const Navigation = ()=>{
    const dispatch = useDispatch()
    const allCountries = JSON.parse(localStorage.getItem(ALL_COUNTRY));
    const { data: countries } = useSelector(state => state.country);
    const { userData } = useSelector(state => state.auth);
    
    useQuery(['GETALLCONRIESGLOBAL'], getAllcountry, {
        enabled: !allCountries,
        onSuccess: o => { localStorage.setItem(ALL_COUNTRY, JSON.stringify(o)) }
    });
    
    useQuery({
        enabled: !countries?.length,
        queryFn: getCountries,
        queryKey: ['GETAVAILBLECOUNTRY'],
        onSuccess: o => dispatch(setCountry({ ...o, selectedCountry: { ...o.data?.filter(i => i.iso === o.ipcountry)?.[0] } }))
    });

    return <Router>
        <Routes>
            {/* Common Route */}
            <Route path='/' element={ <Home />} />
            <Route path='/affiliate-signup' element={ <AffiliateSignUp />} />
            <Route path='/verify-email' element={ <VerifyEmail />} />
            <Route path='/update-email' element={ <VerifyEmail />} />
            <Route path='/terms-and-conditions' element={ <TermsAndCondition />} />
            <Route path='/affiliate' element={<Affiliate/>}/>   
            <Route path='/privacy-policy' element={ <PrivacyPolicy />} />    
            <Route path='/cookie-policy' element={ <CookiePolicy />} />       
            <Route path='/faq' element={ <FAQ />} />
            <Route path='/payment' element={ <Payment />} />
            <Route path='/payment-success' element={ <PaymentSuccess />} />
            <Route path='/compatible-devices' element={ <CompatibleDevices />} />
            <Route path='/redeem-voucher' element={ <RedeemVoucher />} />
            <Route path='/paypal' element={<WebPaypal />} />
            
            {/* Public Route */}
            <Route element={<PublicRoute />} >
                <Route path='/signup' element={ <Signup />} />
                <Route path='/signin' element={ <Signin />} />
                <Route path='/forgot-password' element={ <ForgotPassword />} />
                <Route path='/reset-password' element={ <ResetPassword />} />
            </Route>

            {/* Private Route */}
            <Route element={<PrivateRoute />} >
                <Route path='/my-account' element={ <MyAccount />} />
                <Route path='/change-password' element={ (userData?.signupType !== 'google' && userData?.signupType !== 'apple') ? <ChangePassword /> : <Navigate to='/' /> } />
                <Route path='/my-plans' element={ <MyPlans />} />
                <Route path='/bundle-details' element={ <BundleDetails />} />
                <Route path='/top-up' element={ <TopUp />} />
                <Route path='/notifications' element={ <Notifications />} />
            </Route>

            {/* 404 Page */}
            <Route path='*' element={<NotFoundPage />} />
        </Routes>
    </Router>
}

export default Navigation