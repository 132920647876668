import React from "react";
import { useSelector } from 'react-redux';
import "./TermsAndCondition.scss";
import Layout from "../../components/layout/Layout";
import data from "../../assets/data/privacyPolicy.json";
import * as PR from "../../prime-modules/index";
import ContectInfo from '../../components/home/ContectInfo';

function PrivacyPolicy() {
  const {contact} = useSelector(state=>state.language?.activelang);
  const typeOfDataBody = (rowData) => {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: rowData.typeOfData,
        }}
      />
    );
  };

  const whatDataWeUseBody = (rowData) => {
    let itemData = rowData.whatDataWeUse;
    return (
          <ul>
            {itemData.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
    );
  };

  const whatWeDoBody = (rowData, value) => {
    let itemData;
    if(value === "ourLawfulBasis"){
        itemData = rowData.ourLawfulBasis;
    }else{
        itemData = rowData.whatWeDo;
    }
    return (
      <>
        <div >
        {itemData?.title && <p>{itemData.title}</p>}
        {itemData?.list.length !== 0 && (

          <ul>
            {itemData.list.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
          </ul>
        )}
        </div>
      </>
    );
  };
  return (
    <Layout>
      <section className="about-blocks-section tc-block">
        <div className="terms-and-conditions-section">
          {/* <p className="page">{data.heading}</p> */}
          <h1>{data.heading}</h1>
          <p
            className="subHeading"
            dangerouslySetInnerHTML={{ __html: data.subHeading }}
          />
          <p dangerouslySetInnerHTML={{ __html: data.description }} />
          <p dangerouslySetInnerHTML={{ __html: data.subDescription }} />
          {/* */}
            {data.sections?.map((section) => {
              return Object.keys(section).map((key) => {
                const value = section[key];
                if (key === "title") {
                  return  <div className="terms-section"><h2>{section.title.text}</h2></div>;
                }
                if (key === "listTitle") {
                  return <p>{section.listTitle}</p>;
                }
                if (key === "list") {
                  return (
                    <ul className="sub-number-ol">
                      {section.list.map((listItem, listIndex) => (
                        <li key={listIndex} className="custom-li">
                          <div className="flex">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: listItem.text,
                              }}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  );
                }
                if (key === "content" || key === "content2") {
                  return value.map((text, index) => (
                    <p key={index} dangerouslySetInnerHTML={{ __html: text }} />
                  ));
                }
                if (
                    key === "table" &&
                    section.title.text === "How do you use my personal data?"
                  ) {
                    return (
                      <PR.DataTable
                        value={section.table}
                        className="tb-style"
                      >
                        <PR.Column
                          field="whatWeDo"
                          header="What we do"
                          body={(p)=>whatWeDoBody(p,"whatWeDo")}
                        />
  
                        <PR.Column
                          field="whatDataWeUse"
                          header="What data we use"
                          body={whatDataWeUseBody}
                        />
  
                        <PR.Column
                          field="ourLawfulBasis"
                          header="Our lawful basis"
                          body={(p)=>whatWeDoBody(p,"ourLawfulBasis")}
                        />
                      </PR.DataTable>
                    );
                  }

                if (
                  key === "table" &&
                  section.title.text === "Do you share my personal data?"
                ) {
                  return (
                    <PR.DataTable
                      value={section.table}
                      className="tb-style"
                    >
                      <PR.Column field="recipient" header="Recipient" />
                      <PR.Column
                        field="activityCarriedOut"
                        header="Activity Carried Out"
                      />
                      <PR.Column field="sector" header="Sector" />
                      <PR.Column field="location" header="Location" />
                    </PR.DataTable>
                  );
                }
                if (
                  key === "table" &&
                  section.title.text ===
                    "How long will you keep my personal data?"
                ) {
                  return (
                    <PR.DataTable
                      value={section.table}
                      className="tb-style"
                    >
                      <PR.Column
                        field="typeOfData"
                        header="How long we keep it"
                        body={typeOfDataBody}
                      />
                      <PR.Column
                        field="howLongWeKeepIt"
                        header="Type of data"
                      />
                    </PR.DataTable>
                  );
                }

                return null;
              });
            })}
          {/*  */}
        </div>
      </section>

      {/*** ContactInfo Section ***/}
      <ContectInfo contact={contact}/>
      
    </Layout>
  );
}

export default PrivacyPolicy;
