import React from 'react'
import { snTransformData } from '../../utils/reuse';

const BundlePopupDetailsList = ({labels, setNetworkDialog, countries ,roamingEnabled, duration, dataAmountForDisplay, speed,geog_type, setVisible}) => {
 return (
<div className="scroll-bar-style" style={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}>
    <ul className="mb-2 pb-1 details-list">
        <li>
            <span>{labels?.LBLN0024}</span>
            <span>{dataAmountForDisplay}</span>
        </li>
        <li>
            <span>{labels?.LBLN0025}</span>
            <span>{duration} {duration > 1 ? labels?.LBLN0254: labels?.LBLN0252}</span>
        </li>
        <li>
            <span>{labels?.LBLN0026}</span>
            <span>{speed?.join('/') ?? "N/A"}</span>
        </li>
        <li>
            <span>{labels?.LBLN0027}</span>
            <span className="flex align-items-center text-right">
                <LabelIcon id={labels?.LBLN0106} onClick={setVisible} geog_type={(roamingEnabled.length === 0 || roamingEnabled.length === 1)? "Local" : "Regional"} data={(roamingEnabled.length === 0 || roamingEnabled.length === 1)? countries : roamingEnabled} countShow={true} />
            </span>
        </li>
        <li>
            <span>{labels?.LBLN0028}</span>
            <span className="flex align-items-center text-right">
            { (roamingEnabled.length === 0 || roamingEnabled.length === 1) 
            ? <LabelIcon id={labels?.LBLN0292} onClick={setNetworkDialog} data={countries?.[0]?.networks} countShow={true} />
            : <LabelIcon id={`${labels?.LBLN0029} ${labels?.LBLN0030}`} countShow={false} onClick={setNetworkDialog} data={(roamingEnabled.length === 0 || roamingEnabled.length === 1)? countries?.[0]?.networks : roamingEnabled} />}
            </span>
        </li>
    </ul>
</div>
  )
}

export default BundlePopupDetailsList


export const LabelIcon = ({ onClick, data, id, countShow, geog_type }) => {
  if (!data || data.length === 0) return "N/A";

  const displayText = data.length === 1
    ? geog_type === "Local"
      ? data[0]?.country?.name?.split("(")[0] 
      : snTransformData(data)[0]
    : `${countShow ? data.length : ''} ${id}`;

  return (
    <>
      {displayText}
      {data.length > 1 && (
        <div className="icon-blk ml-2 cursor-pointer" onClick={onClick}>
          <i className="pi pi-eye"></i>
        </div>
      )}
    </>
  );
};