import React from 'react'
import { useTranslation } from 'react-i18next';

import * as PR from "../../prime-modules/index";
import getAssets from "../../assets/data/brandingAssets.json"
import { getImageSrc } from '../../utils/reuse';
import faq_en from "../../assets/data/faq_en.json";
import faq_es from "../../assets/data/faq_es.json";
import faq_pt from "../../assets/data/faq_pt.json";

const faqObject = {
    en: faq_en,
    es: faq_es,
    pt: faq_pt
}

function Faq({navigate, home}) {

    let faq_image = getImageSrc(home?.HOMEN0084, getAssets.home.faq_image)

  const { i18n: { language: ln } } = useTranslation();
  const faq = faqObject[ln.split('-')[0]] ?? [];

  return (
    <section id="faq" className="faqs-section">
    <div className="card">
        <div className='text-center'>
            <h2>{home?.HOMEN0059}</h2>
            {/* Removed as per Chandru's instructions. */}
            {/* <p>{labels?.LBLN0203}</p> */}
        </div>
        <div className='grid custom-margin'>
            <div className="col-12 lg:col-6">
                <div className="faq-wrapper">
                   {faq?.length > 0 && faq[0]?.QA?.slice(0, 4)?.map((item, index) => (<div key={index} className='faq-list'>
                        <h3>{item.question}</h3>
                        <p>{item?.answer[0]?.content?.slice(0,100)}... <span className='see-more' onClick={navigate.bind(null, "/faq",{state:item?.question})}>see more</span></p>
                    </div>
                        )
                    )}
                    <PR.Button onClick={navigate.bind(null, "/faq")} label={home?.HOMEN0060} className="faq-button" />
                </div>
            </div>
            <div className="col-12 lg:col-6 text-center">
                <PR.Image src={faq_image} alt="Faq image" className='faq-image'/>
            </div>
        </div>
    </div>
</section>
  )
}

export default Faq