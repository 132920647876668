import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';

import { getBundleslist, getCountries, getCountriesList } from '../../services/api';
import { setCountry, setSelectCountry } from '../../store/country.slice';
import BundleDetailsPopup from './BundleDetailsPopup';
import CountrySearch from '../form/CountrySearch';
import BundlesCard from './bundlesCard';
import './Bundle.scss'
import getAssets from "../../assets/data/brandingAssets.json"
import * as PR from "../../prime-modules/index";

const BundleSection = ({iccid}) => {

    const { selectedCountry, data: countries } = useSelector(state => state.country);
    const {labels} = useSelector(state=>state.language?.activelang);
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const { ipcountry } = useSelector(state => state.country);
    const [veiwDetails, setViewDetails] = useState(null);
    const [filterTabList, setFilterTabList] = useState([]);
    const [currentFilterTab, setCurrentFilterTab] = useState([]);
    const [bundleFilterList, setBundleFilterList] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isMobile = useMediaQuery('only screen and (max-width: 768px)');
    const [viewMore, setViewMore] = useState(false);

    const renderList = () => {
      const displayedItems = viewMore ? bundleListData : bundleListData.slice(0, 5);
      return (
        <div className="list-container">
         <div className="grid justify-content-center">
              {displayedItems?.map?.((item,index) =>
                   <div key={index} className={`col-12 md:col-6 sm:col-6 lg:col-4`}>
                      <BundlesCard
                        labels={labels}
                        iso={selectedCountry?.iso}
                        onClickBuyNow={buyNowHanlder}
                        onClickDetails={setViewDetails}
                        isLoading={bundleload}
                        data={item}
                        currency={bundleList?.currencySymbol}
                        doNotSell={bundleList?.doNotSell}
                      />
                  </div>
              )}
          </div>
        {!viewMore && bundleListData.length > 5 && (
          <Button label={labels?.LBLN0310} icon="pi pi-angle-double-down" onClick={() => setViewMore(true)} className="view-more-button" >
              <i className="pi pi-angle-double-down onemore-arrow-icon"></i>
          </Button>
        )}
        </div>
      );
    };

    const carouselTemplate = (item) => (
      <BundlesCard
        labels={labels}
        iso={selectedCountry?.iso}
        onClickBuyNow={buyNowHanlder}
        onClickDetails={setViewDetails}
        isLoading={bundleload}
        data={item}
        currency={bundleList?.currencySymbol}
        doNotSell={bundleList?.doNotSell}
      />
    );

    const renderCarousel = () => (
      <Carousel
        value={bundleListData}
        numVisible={1}
        numScroll={1}
        itemTemplate={carouselTemplate}
      />
    );

    const { isLoading: countriesLoading } = useQuery({
        enabled: !selectedCountry||!countries?.length,
        queryFn: () => iccid ? getCountriesList(url) : getCountries(),
        queryKey: ['getContries'],
        onSuccess: o => dispatch(setCountry({ ...o, selectedCountry: { ...o.data?.filter(i => i.iso === o.ipcountry)?.[0] } }))
    });

    const { data: bundleList, isLoading: bundleListLoading, error: bundleListError } = useQuery({
        queryFn: () => getBundleslist(`countries=${encodeURIComponent(selectedCountry?.iso)}&page=&limit=&direction=asc&orderBy=price&filter=&filterBy=`),
        queryKey: [selectedCountry?.iso || iccid],
        enabled: !!selectedCountry?.iso,
        refetchOnWindowFocus: false,
        retry: 0,
        onSuccess: (data) => {
            if(!iccid) return
            let filter = [];
            data.bundles?.forEach((bundle) => {
              if (!filter.includes(bundle.dataAmountForDisplay)) {
                filter.push(bundle.dataAmountForDisplay);
              }
            });
            const getNumericValue = (str) => {
              const match = str.match(/(\d+)/);
              return match ? parseInt(match[1], 10) : Infinity;
            };
            const sortedList = filter.sort((a, b) => {
              const numA = getNumericValue(a);
              const numB = getNumericValue(b);
              if (numA === Infinity) return 1;
              if (numB === Infinity) return -1;
              return numA - numB;
            });
            setFilterTabList(sortedList);
            setBundleFilterList(data?.bundles?.sort((a, b) => a.price - b.price));
          },
    });
    
    const userLocatedCountry = countries?.find(c => c.iso === ipcountry)?.name;
    const purchseRestrictedMessage = userLocatedCountry ? `${labels.LBLN0300} ${userLocatedCountry} ${labels.LBLN0301}` : labels.LBLN0302

    const url = isAuth && iccid ? "countries?iccid=" + iccid : "countries";

    const filterBundle = (tabs) =>
      bundleList?.bundles?.filter((bundle) =>
        tabs.length === 0 ? true : tabs.includes(bundle.dataAmountForDisplay)
      );

    const handleFilterTab = (tab) => {
        let filterTab = [];
        if (currentFilterTab.includes(tab)) {
          setCurrentFilterTab((tabs) => {
            filterTab = tabs.filter((currentTab) => currentTab !== tab);
            return filterTab;
          });
        } else {
          setCurrentFilterTab((tabs) => {
            filterTab = [...tabs, tab];
            return filterTab;
          });
        }
        setBundleFilterList(() => filterBundle(filterTab));
      };
  
    const bundleload = bundleListLoading || countriesLoading;
    const bundleListData = bundleListError ? [] :(bundleload ? new Array(3).fill(' ') : bundleFilterList?? bundleList?.bundles?.sort((a, b) => a.price - b.price))
    const buyNowHanlder = data => navigate('/payment', {state:{bundle:{...data,iccid: iccid?? ""}}});

    useEffect(() => {
      setViewMore(false);  // Reset to false when data changes
    }, [bundleListData]);

    return <section className="bundles-section" id='plans'>
        <div className="flex align-items-center justify-content-center gap-4">
            <CountrySearch initial={selectedCountry?.name} placeholder={labels?.LBLN0251} countries={countries} onSelected={ v => dispatch(setSelectCountry(v)) } />
        </div>
        {bundleList?.doNotSell && <p className='text-center text-white  mt-4'><sup className='err-msg text-lg'>*</sup> {purchseRestrictedMessage}</p>}
          <div className="filter-tab">
            {filterTabList.map((tab, index) => (
              <p
                key={index}
                onClick={handleFilterTab.bind(null, tab)}
                className={
                  currentFilterTab.includes(tab) ? "active-p" : "inactive-p"
                }
              >
                {tab}
              </p>
            ))}
          </div>
          {bundleListData.length !== 0 ? <div className='bundles-list-section'>
            {isMobile ? renderCarousel() : renderList()}
          </div>:<div className='coming-soon-section'>
              <PR.Image src={getAssets.logo.logo_dark} alt="data2go logo" className="logo-img" />
              <p className='text-center'>Coming Soon...</p>
            </div>}
          <BundleDetailsPopup  onClickBuyNow={buyNowHanlder} onClickCancle={setViewDetails} currency={bundleList?.currencySymbol} data={veiwDetails} doNotSell={bundleList?.doNotSell} />
    </section>
}

export default BundleSection;