import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { envConfig } from "../../services/api";
import { createPayPalOrder } from "./helper";

const PaypalPayment = ({ stateBundle, dispalyPrice, onPayment }) => {
  const initialOptions = {
    "client-id": envConfig.REACT_APP_PAYPAL_CLIENT_ID,
  };

  const { name, description } = stateBundle ?? {};

  const onApprove = (data, actions) => {
    return actions.order.capture().then((e) => {
      const paymentMethodId = e.id;
      if (paymentMethodId) {
        const paymentGateway = "Paypal";
        const capturesInfo = e.purchase_units[0]?.payments?.captures;
        const captureID = capturesInfo?.length ?  capturesInfo[0]?.id : '';
        const contactInfo = {
          firstName: e.payer.name.given_name,
          lastName: e.payer.name?.surname ?? "",
          email: e.payer.email_address,
          phone: {
            callingCode: "",
            localPhoneNumber: "",
          }
        }
        onPayment({ contactInfo, paymentMethodId, paymentGateway, captureID })
      }
    });
  };
  const onError = (data, actions) => {};

  return (
    <>
      <div className="col-12 md:col-12">
        <div className="input-wrapper paypal-section-button">
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              fundingSource="paypal"
              style={{ layout: "vertical" }}
              createOrder={createPayPalOrder.bind(null, { name, description, dispalyPrice })}
              onApprove={onApprove}
              onError={onError}
              onCancel={onError}
            />
          </PayPalScriptProvider>
        </div>
      </div>
    </>
  );
};

export default PaypalPayment;
