
import './Bundle.scss'
import {useState } from "react";
import { useSelector } from "react-redux";
import BundlePopupHeader from "./BundlePopupHeader";
import BundlePopupDetailsList from "./BundlePopupDetailsList";
import BundlePopupRedeemFooter from "./BundlePopupRedeemFooter";
import RoamingPopup from "./RoamingPopup";
import NetworksPopup from "./NetworksPopup";

const VoucherBundleDetails = ({topUpExistingESIM, createOrTopupEsim, bundle, topupData }) => {
    const {labels} = useSelector(state=>state.language?.activelang);

    const [visible, setVisible] = useState(false);
    const [networkDialog, setNetworkDialog] = useState(null);

if (!bundle) return
let {roamingEnabled, countries } = bundle ?? {}
roamingEnabled = roamingEnabled?.length > 0 ? roamingEnabled : [];

    return (
    <>
        <BundlePopupHeader currency={""} labels={labels} redeemPage={true} {...bundle}/>
        <BundlePopupDetailsList labels={labels} setNetworkDialog={setNetworkDialog} {...bundle} setVisible={setVisible} />
        <BundlePopupRedeemFooter providerCode={bundle?.providerCode} createOrTopupEsim={createOrTopupEsim} topUpExistingESIM={topUpExistingESIM} bundle={bundle} topupData={topupData} />
        <RoamingPopup labels={labels} setVisible={setVisible} visible={visible} roamingEnabled={roamingEnabled} />
        <NetworksPopup labels={labels} setVisible={setVisible} networkDialog={networkDialog} roamingEnabled={roamingEnabled} countries={countries} setNetworkDialog={setNetworkDialog} />
   </>)
}

export default VoucherBundleDetails;