import React from "react";
import "../../pages/redeem-voucher/RedeemVoucher.scss";
import * as PR from "../../prime-modules/index";
import { useSelector } from "react-redux";

const RedeemVoucherTopUp = ({ voucherTopUpData, createOrTopupEsim }) => {
  const { myAccount:account, redeemVoucher } = useSelector(state => state.language?.activelang);

  return (
    <>
      <div className="vouchers-box">
        <div className="topup-esim text-center">
          <h2>{redeemVoucher.VOUC012}</h2>
          {voucherTopUpData && voucherTopUpData?.map(v => {
            return (
              <div className="esim-list">
                <div className="flex align-items-center justify-content-between">
                  <div className="left">
                    <p>{account.ACTN0015}: {v?.tag}</p>
                    <span>{account.ACTN0023} : {v?.iccid}</span>
                  </div>
                  <div className="right">
                    <PR.Button label={redeemVoucher.VOUC010} type='submit' className="topup-btn" onClick={() => createOrTopupEsim(v?.iccid)} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
};

export default RedeemVoucherTopUp;