import { CustomCheckoutProvider  } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import PaypalPayment from "./Paypal";
import { loadStripe } from "@stripe/stripe-js";

import { envConfig } from "../../services/api";
import * as PR from "../../prime-modules/index";
import Input from "../../components/form/Input";
import { cardPaymentSchema } from "../../utils/schema";
import getAssets from "../../assets/data/brandingAssets.json"
import { CheckCompatible, elementsOptions } from "./helper";
import CardPayment from "./CardPayment";

const stripePromiseV2 = loadStripe(envConfig.REACT_APP_STRIPE_PUBLIC_KEY,  {
    betas: ['custom_checkout_beta_4'],
  });

let { payment_card_white, payment_paypal, } = getAssets.payment

const PaymentTab = ({checked, setChecked, onPayment, dispalyPrice, promoCode, stateBundle, formikRef, v2PayHandler, clientSecretLoading, paypalPaymentLoading, state }) => {
    const {labels} = useSelector(state=>state.language?.activelang);

    const { userData: userInfo } = useSelector((state) => state.auth);

    return (
      <>
        <PR.TabView>
          <PR.TabPanel header="Card" headerTemplate={paymentCard}>
            <div className="card-section">
              <Formik
                innerRef={formikRef}
                initialValues={{
                  email: userInfo?.email ?? state?.userData?.email ?? "",
                  firstName:
                    userInfo?.firstName ?? state?.userData?.firstName ?? "",
                }}
                validationSchema={cardPaymentSchema}
                onSubmit={(values) => v2PayHandler(values, true, {success: state?.success ?? null, promoCode: state?.promoCode ?? null})}
              >
                <Form autoComplete="off" className="form-block">
                  <div className="grid">
                    <div className="col-12 md:col-12">
                      <Input
                        disabled={!!userInfo?.firstName}
                        id={"firstName"}
                        name={"firstName"}
                        label={labels?.LBLN0001}
                        type={"text"}
                        aria-describedby={"f-name"}
                        placeholder={labels?.LBLN0207}
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <Input
                        disabled={!!userInfo?.email}
                        id={"email"}
                        name={"email"}
                        label={labels?.LBLN0275}
                        type={"email"}
                        aria-describedby={"email"}
                        placeholder={labels?.LBLN0209}
                      />
                    </div>
                  </div>

                  {!state?.clientSecret && !userInfo?.email && (
                    <div className="flex flex-wrap">
                      <PR.Button
                        label={labels?.LBLN0152}
                        className="pay-button mt-4"
                        type="submit"
                        disabled={clientSecretLoading}
                      />
                    </div>
                  )}
                </Form>
              </Formik>
              {clientSecretLoading && (
                <div className="payment-sheet-changing-loading">
                  <i className="pi pi-spin pi-spinner"></i>
                </div>
              )}
              {!clientSecretLoading &&
                (!!userInfo?.email || !!state?.userData) &&
                state?.clientSecret && (
                  <CustomCheckoutProvider
                    stripe={stripePromiseV2}
                    options={{
                      clientSecret: state?.clientSecret,
                      elementsOptions,
                    }}
                  >
                    <CardPayment
                      checked={checked}
                      setChecked={setChecked}
                      dispalyPrice={dispalyPrice}
                      userInfo={
                        userInfo?.email
                          ? userInfo
                          : state?.userData
                          ? state.userData
                          : {}
                      }
                    />
                  </CustomCheckoutProvider>
                )}
            </div>
          </PR.TabPanel>
          <PR.TabPanel header="Paypal" headerTemplate={paymentPaypal}>
            <div className="card-section">
              <CheckCompatible
                checked={checked}
                setChecked={setChecked}
                id="payPalPayment"
                labels={labels}
              />
              <div
                className={`flex flex-wrap mb-1 ${
                  !checked.payPalPayment || paypalPaymentLoading
                    ? "btn-disable"
                    : ""
                }`}
              >
                <PaypalPayment
                  stateBundle={stateBundle}
                  dispalyPrice={dispalyPrice}
                  onPayment={onPayment}
                  key={!!promoCode?.success}
                />
              </div>
              <div className="flex flex-wrap">
                <p>{labels?.LBLN0276}</p>
              </div>
            </div>
          </PR.TabPanel>
        </PR.TabView>
      </>
    );
}

export default PaymentTab;

const paymentCard = (options) => {
    return (
        <div className="tab flex align-items-center gap-2" onClick={options.onClick}>
            <PR.Image src={payment_card_white} alt="Card" />
            <span className="">Card</span>
        </div>
    );
};
const paymentPaypal = (options) => {
    return (
        <div className="tab" onClick={options.onClick}>
            <PR.Image src={payment_paypal} alt="Paypal" />
        </div>
    );
};
