import React from "react";
import { Form, Formik } from "formik";
import {  useMutation } from "react-query";
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import * as PR from "../../prime-modules/index";
import Layout from "../../components/layout/Layout";
import Input from "../../components/form/Input";
import { siginValidationSchema } from "../../utils/schema";
import { handleSignin } from "../../services/api";
import { login } from "../../store/auth.slice";
import GoogleLogin from '../../components/auth/GoogleLogin'
import AppleLogin from "../../components/auth/AppleLogin";
import { useToast } from "../../context/ToastContext";

const Signin = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const {showToast} = useToast()
    const {labels} = useSelector(state=>state.language?.activelang);

    const { mutate, isLoading } = useMutation(handleSignin, {
      onSuccess: data => { 
        // navigate('/')
        dispatch(login({ ...data, isTopupVoucher :  location.hash === "#topup"}))
       },
      onError:(o)=> showToast("error",o?.message)
    });

    return (
      <>
        <Layout>
          <section className="signup-section">
            <div className="grid">
              <div className="col-12 text-center">
                <h1>{labels?.LBLN0230}</h1>
                <p>{labels?.LBLN0231}</p>
                <div className="signup-wrapper">
                  {/* Form section starts here */}
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={siginValidationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      mutate(values);
                      setSubmitting(false);
                    }}
                  >
                    <Form autoComplete="off" className="form-block">
                      <div className="grid">
                        <div className="col-12 md:col-12">
                          <Input
                            label={labels?.LBLN0037}
                            name={"email"}
                            type={"email"}  
                            aria-describedby={"email"}
                            placeholder={labels?.LBLN0209}
                          />
                        </div>
                        <div className="col-12 md:col-12">
                          <Input
                            feedback={false}
                            label={labels?.LBLN0071}
                            name={"password"}
                            type={"password"}
                            aria-describedby={"password"}
                            toggleMask
                            placeholder={labels?.LBLN0228}
                          />

                        </div>
                        <div className="col-12 pb-0">
                          <PR.Button
                            disabled={isLoading}
                            label={labels?.LBLN0068}
                            type="submit"
                            className="signup-button w-12"
                          />
                          <p className="text-right forgot-pass-p">
                            <Link to="/forgot-password">{labels?.LBLN0033}</Link>
                          </p>
                        </div>
                      </div>
                      <div className="grid mt-4 mb-3">
                        <GoogleLogin labels={labels} />
                        <AppleLogin labels={labels} />
                      </div>
                      <p className="link-p">
                      {labels?.LBLN0070} <Link to="/signup">{labels?.LBLN0226}</Link>
                      </p>
                    </Form>
                  </Formik>
                  {/* Form section ends here */}
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    );
};

export default Signin;
