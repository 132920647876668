import React from 'react'
import * as PR from "../../prime-modules/index";
import { rcTransformData } from '../../utils/reuse';

const RoamingPopup = ({labels ,roamingEnabled,visible, setVisible}) => {
  return (
    <PR.Dialog closeOnEscape dismissableMask className="bundle-detail-blk" style={{ maxWidth: '1200px' }} header={labels?.LBLN0107} visible={visible} blockScroll={true} draggable={false} onHide={() => { if (!visible) return; setVisible(false); }} >
    <div className="content">
        <ul style={{ columnCount: 3 }}>
            {rcTransformData(roamingEnabled)?.map((el, i) => <li key={i}>{el?.split("(")[0]}</li>)}
        </ul>
    </div>
</PR.Dialog>
  )
}

export default RoamingPopup
