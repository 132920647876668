import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as PR from "../../prime-modules/index";
import { globalConfig } from '../../constants';
import getAssets from "../../assets/data/brandingAssets.json"

let { visa, master_card, apple_pay, g_pay, twitter, linkedin, facebook, instagram_icon, whats_app } = getAssets.icons

const Footer = () => {
    const navigate= useNavigate();
    const [year] = useState(new Date().getFullYear())
    const {labels} = useSelector(state=>state.language?.activelang);
    
    return (
    <>
        <footer className="footer-section">
            <div className='footer-info'>
                <div className='grid'>
                    <div className="col-12 md:col-6 lg:col-3 order-1">
                        <PR.Image src={getAssets.logo.footer_logo} alt="data2go logo"/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-3 order-2">
                        <p><Link to={"/affiliate"} className='custom-fontSize'>{labels?.LBLN0298}</Link></p>
                        <p><Link to={'https://wa.me/message/7H7PZX53WH7PJ1'} target='_blank' className="email-link">
                            <PR.Image src={whats_app} alt="WhatsApp" className='whatsapp-icon'/> {labels?.LBLN0324}
                        </Link>
                        </p> 
                        <p><Link to={`mailto:${globalConfig.salesEmail}`} className="email-link">{globalConfig.salesEmail}</Link></p>
                    </div>
                    <div className="col-12 md:col-6 lg:col-3 order-3">
                        <p className='footer-button' onClick={navigate.bind(null,"/privacy-policy")}>{labels?.LBLN0075}</p>
                        <p className='footer-button' onClick={navigate.bind(null,"/terms-and-conditions")}>{labels?.LBLN0178}</p>
                        <p className='footer-button' onClick={navigate.bind(null,"/cookie-policy")}>{labels?.LBLN0128}</p>
                    </div>
                    <div className="col-12 md:col-6 lg:col-3 order-4">
                        <h3>{labels?.LBLN0179}</h3>
                        <div className='flex align-center gap-2 payment-accept'>
                            <PR.Image src={visa} alt="Visa"/>
                            <PR.Image src={master_card} alt="Master Card"/>
                            <PR.Image src={apple_pay} alt="Apple Pay"/>
                            <PR.Image src={g_pay} alt="Google Pay"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='copyrights-section'>
                <div className='flex align-center justify-content-between mobile-flex'>
                    <div className='copyrights-left'>
                        <p>&copy;{year} {labels?.LBLN0065} | {labels?.LBLN0064} <Link to="https://www.bluehouse-technology.co.uk/" target='_blank'>Bluehouse Technology.</Link></p>
                    </div>
                    <div className='social-icons flex align-center gap-4'>
                        <Link to="https://x.com/data2go_mobi" target='_blank'><PR.Image src={twitter} alt="Twitter"/></Link>
                        <Link to="https://uk.linkedin.com/company/bluehouse-technology" target='_blank'><PR.Image src={linkedin} alt="LinkedIn"/></Link>
                        <Link to="https://www.facebook.com/data2go.mobi" target='_blank'><PR.Image src={facebook} alt="Facebook"/></Link>
                        <Link to="https://www.instagram.com/data2go.mobi" target='_blank'><PR.Image src={instagram_icon} alt="Instagram"/></Link>
                    </div>
                </div>
            </div>
            <PR.ScrollTop className='scroll-to-top' icon="pi pi-arrow-up" />
        </footer>
    </>
  );
};

export default Footer; 