import React from 'react'
import { setNumberPrecision } from '../../utils/common'

const BundlePopupHeader = ({labels,simtype, description, redeemPage, currency, price}) => {
  return (
    <>
      <div className="header-title">
        <div className='flex justify-content-between align-items-center details-header'>
          <h4>{labels?.LBLN0016}</h4>
          {redeemPage ? null:  <p className="price">{currency}{setNumberPrecision(price)}</p>}
        </div>
        <p className="desc">{simtype}, {description?.split("(")[0]}</p>
      </div>
    </>
  )
}

export default BundlePopupHeader