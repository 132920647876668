import React, { useEffect } from 'react';
import Header from "./HeaderMenu";
import Footer from "./Footer";
import { useLocation } from 'react-router-dom';

const Layout = ({children}) => {
    
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <div className='main-section'>
                <Header/>
                    {children}
                <Footer />
            </div>
        </>
    );
};

export default Layout;