import React, {useRef, useState} from "react";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import * as PR from "../../prime-modules/index";
import Layout from "../../components/layout/Layout";
import Input from "../../components/form/Input";
import { profileValidationSchema } from "../../utils/schema";
import { deleteAccountAPI, resendOTP, updateProfile } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { logout, updateUserData } from "../../store/auth.slice";
import { useToast } from "../../context/ToastContext";
import { Link, useNavigate } from "react-router-dom";
import { ALL_COUNTRY } from "../../utils/localstore";

const MyAccount = () => {

    const {showToast} = useToast()
    const navigate = useNavigate();
    const userupdatedData = useRef();
    const dispatch = useDispatch();
    const {userData : userInfo} = useSelector(state=> state.auth);
    const {labels} = useSelector(state=>state.language?.activelang);
    const { selectedCountry } = useSelector(state => state.country);
    const allCountries = JSON.parse(localStorage.getItem(ALL_COUNTRY));
    const form = useRef();
    const [deleteAccount, setDeleteAccount] = useState({});

    const { mutate } = useMutation(updateProfile, {
      onSuccess: () => {
        if(form.current.values.email !== userInfo?.email){
          showToast("success",labels?.LBLN0286)
          setTimeout(()=>{
            dispatch(logout());
          },2000)
          return
        }
        dispatch(updateUserData({...userInfo, ...userupdatedData.current}));
        showToast("success",labels?.LBLN0286)
      },
      onError : o =>showToast("error", o?.message),
    });

    const handleSubmit = (value) => {
      let phone = { callingCode: "", localPhoneNumber: "" };

      if (value?.phone.includes("-")) {
        const [callingCode, localPhoneNumber] = `+${value?.phone}`.split("-");
        phone = { callingCode, localPhoneNumber };
      } else {
        phone = {
          callingCode: userInfo?.phone?.callingCode ?? "",
          localPhoneNumber: userInfo?.phone?.localPhoneNumber ?? "",
        };
      }
      userupdatedData.current = { ...value, userId: userInfo.userId, phone }
      mutate(userupdatedData.current);
    };


    const { mutate: deleteAccountHandler } = useMutation(deleteAccountAPI, {
      onSuccess: () => {
        setDeleteAccount({...deleteAccount, message: labels.LBLN0317})
        setTimeout(() => {
          setDeleteAccount({});
          dispatch(logout());
        }, [2000])
      },
      onError : o =>{
        setDeleteAccount({show: true});
        showToast("error", o?.message)
      },
    });


    const {isLoading, mutate: sendVerifyOtp } = useMutation(resendOTP, {
      onSuccess: (o) => {
        showToast("success", labels?.LBLN0282)
        navigate(`/verify-email?id=${userInfo?.userId}`)
      },
      onError : o =>{
        showToast("error", o?.message)
      },
    });
    return (
      <>
        <Layout>
          <PR.Dialog visible={!!deleteAccount?.show} onHide={() => setDeleteAccount({show: false})} className="logout-dialog" baseZIndex={1} >
            <div className="flex align-items-center gap-3">
              <div className="logout-icon">
                <i className="pi pi-trash"></i>
              </div>
              <h2>{labels.LBLN0314}</h2>
            </div>
            <p>{labels.LBLN0315}</p>
            <p className="small">{labels.LBLN0316}</p>
            <div className="col-12 p-0">
              <PR.Button
                label={labels.LBLN0314}
                type="submit"
                className="delete-button"
                disabled={deleteAccount?.disable}
                onClick={() => {
                  setDeleteAccount({...deleteAccount, disable: true})
                  deleteAccountHandler()
                }}
              />
            </div>
            {deleteAccount?.message && <p className="delete-success">{labels.LBLN0317}</p>}
          </PR.Dialog>
          <section className="signup-section">
            <div className="grid">
              <div className="col-12 text-center">
                <h1>{labels?.LBLN0080}</h1>
                <p>{labels?.LBLN0236}</p>
                <div className="signup-wrapper">
                  {/* Form section starts here */}
                  <Formik
                  innerRef={form}
                    initialValues={{
                      firstName: userInfo?.firstName,
                     // lastName: userInfo?.lastName,
                      email: userInfo?.email,
                      phone: !!userInfo?.phone?.callingCode ? `${userInfo?.phone?.callingCode}${userInfo?.phone?.localPhoneNumber}` : `${allCountries?.filter(e=> selectedCountry?.iso===e.iso)?.[0]?.callingCode}` ,
                    }}
                    validationSchema={profileValidationSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form autoComplete="off" className="form-block">
                      <div className="grid">
                        <div className="col-12">
                          <Input
                              label={labels?.LBLN0001}
                              name={"firstName"}
                              type={"text"}
                              aria-describedby={"firstName"}
                              placeholder={labels?.LBLN0207}
                          />
                        </div>
                        {/* <div className="col-12 md:col-6">
                            <Input
                              label={labels?.LBLN0002}
                              name={"lastName"}
                              type={"text"}
                              aria-describedby={"lastName"}
                              placeholder={labels?.LBLN0208}
                            />
                        </div> */}
                        <div className="col-12 md:col-12">
                        <div className="flex justify-content-between items-center input-field w-full">
                          <label className="text-left">
                            {labels?.LBLN0037}
                          </label>
                         {userInfo?.isEmailVerified ? <label
                            className="text-green-500 text-right"
                          >
                            {labels?.LBLN0322 || "Verified"}
                          </label>: <label
                            onClick={
                              isLoading
                                ? {}
                                : sendVerifyOtp.bind(null, {
                                    email: userInfo?.email,
                                  })
                            }
                            className="text-red-500 text-right underline cursor-pointer"
                          >
                            {labels?.LBLN0321}
                          </label>}
                        </div>

                            <Input
                              label={""}
                              rightLabel={labels?.LBLN0037}
                              name={"email"}
                              type={"text"}
                              disabled={userInfo?.signupType === 'google' || userInfo?.signupType === 'apple'}
                              aria-describedby={"email"}
                              placeholder={labels?.LBLN0209}
                            />
                        </div>
                        <div className="col-12 md:col-12">
                            <Input id={'phone'} name={'phone'} label={labels?.LBLN0011} type={'text'} aria-describedby={'phone'}   />    
                        </div>
                        <div className="col-12">
                          <PR.Button
                            label={labels?.LBLN0237}
                            type="submit"
                            className="signup-button w-12"
                          />
                        </div>
                         
                      </div>
                    </Form>
                  </Formik>
                  <div className="text-center mt-3">
                  <Link
                    className="delete-button"
                    onClick={() => setDeleteAccount({ show: true })}
                  >
                    <i className="pi pi-trash"></i>
                    {labels.LBLN0314}
                  </Link>
                  </div>
                  {/* Form section ends here */}
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    );
};

export default MyAccount;
