import React from 'react'
import { useDispatch } from 'react-redux';

import * as PR from "../../prime-modules/index";
import { setSelectCountry } from '../../store/country.slice';
import getAssets from "../../assets/data/brandingAssets.json"
import { getImageSrc } from '../../utils/reuse';

function GlobalCoverage({navigate, home}) {
    const dispatch = useDispatch();
    let choose_plan = getImageSrc(home?.HOMEN0083, getAssets.home.choose_plan)
    const clickHandler = ()=>{
        navigate('/#plans');
        dispatch(setSelectCountry({ name: "Global", iso: "Global" }));
    }
  return (
    <section className="coverage-section" id="coverage">
    <div className="coverage-container">
        <div className="grid align-items-center">
            <div className="col-12 lg:col-6">
                <div className='custom-padding'>
                    <h2>{home?.HOMEN0015}</h2>
                    <p>{home?.HOMEN0016}</p>
                    <PR.Button label={home?.HOMEN0017} className="choose-plan-button" onClick={clickHandler} />
                </div>
            </div>
            <div className="col-12 lg:col-6">
                <PR.Image src={choose_plan} alt="Global coverage"/>
            </div>
        </div>
    </div>
</section>
  )
}

export default GlobalCoverage