import React from "react";
import { useSelector } from 'react-redux';
import "./TermsAndCondition.scss";
import Layout from "../../components/layout/Layout";
import data from "../../assets/data/termsAndConditions_en.json";
import ContectInfo from '../../components/home/ContectInfo';

function TermsAndCondition() {
  const {contact} = useSelector(state=>state.language?.activelang);
  return (
    <Layout>
      <section className="about-blocks-section tc-block">
        <div className="terms-and-conditions-section">
          {/* <p className="page">{data.heading}</p> */}
          <h1>{data.heading}</h1>
          <p className="subHeading" dangerouslySetInnerHTML={{ __html: data.subHeading }} />
          <p  dangerouslySetInnerHTML={{ __html: data.description }} />
          {data.sections?.map((section, sectionIndex) => (
            <div key={sectionIndex} className="terms-section">
              <h2>{section.title.text}:</h2>
              <ul className="sub-number-ol">
                {section.content?.map((item, itemIndex) => (
                  <li key={itemIndex} className="custom-li">
                    <div className="flex">
                      <span dangerouslySetInnerHTML={{ __html: item.text }} />
                    </div>
                    {item.list && (
                      <ul>
                        {item.list.map((listItem, listIndex) => (
                          <li key={listIndex} className="custom-li">
                            <div className="flex">
                              <span>{listItem.text}</span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
              {section.SubDescription && <p>{section.SubDescription}</p>}
              {section.SubText && <p>{section.SubText}</p>}
            </div>
          ))}
        </div>
      </section >

      {/*** ContactInfo Section ***/}
      <ContectInfo contact={contact}/>

    </Layout>
  );
}

export default TermsAndCondition;
