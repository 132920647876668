import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useMutation, useQuery } from "react-query";
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from "react-redux";
import * as PR from "../../prime-modules/index";
import Layout from "../../components/layout/Layout";
import "./RedeemVoucher.scss";
import Input from "../../components/form/Input";
import { redeemVoucherValidationSchema } from "../../utils/schema";
import { discountPayment, getTopUpPrepaidBundle, redeemVoucherAPI } from "../../services/api";
import { useToast } from "../../context/ToastContext";
import VoucherBundleDetails from "../../components/bundle/VoucherBundleDetails";
import RedeemVoucherTopUp from "../../components/bundle/RedeemVoucherTopUp";
import { useNavigate } from "react-router-dom";
import { getPhoneObj } from "../../utils/reuse";
import { removeVoucherCode, storeVoucherInfo } from "../../store/auth.slice";

const RedeemVoucher = () => {
    const { showToast } = useToast()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { labels, redeemVoucher } = useSelector(state => state.language?.activelang);
    const {isAuthenticated:isAuth,promoCode, userData} = useSelector((state) => state.auth);
    const [userFormData, setUserFormData] = useState({});
    const [topup, setTopup] = useState(false);
    
    const { mutate, isLoading,data } = useMutation(redeemVoucherAPI, {
        onError: (o) => showToast("error", o?.message)
    });
    useEffect(() => {
        if (promoCode) {
            setUserFormData(prev => ({ ...prev, promoCode }));
            dispatch(removeVoucherCode())
        }
    }, [promoCode, dispatch])
    

   /*  useEffect(() => {
        if (isAuth && promoCode) {
          mutate({promoCode: promoCode?.replaceAll("-", "")?.toLowerCase(),email:userData?.email ?? ""}); // Trigger the mutation with the voucher code
        }
      }, [mutate,isAuth,promoCode,userData]); */


    const { mutate:discountPaymentMutate, isLoading:discountPaymentIsLoading } = useMutation(discountPayment, {
        onSuccess: data => {
            navigate('/payment-success', { state: {...data, redeemVoucher:true} })
        },
        onError: (o) => showToast("error", o?.message)
    });

   const createOrTopupEsim = (iccid)=>{
    const customerDetails = {
        firstName: userData?.firstName ? userData?.firstName : userFormData?.email?.split('@')[0],
        email: userData?.email ? userData?.email : userFormData.email,
        phone: getPhoneObj('', isAuth, userData, '')
    }
    let {name,description,price,speed,duration,dataAmount} = data?.bundle;

    const dataPlans = [
        {
            name,
            description,
            quantity: 1,
            price,
            speed,
            duration,
            dataAmount,
            promoCode: userFormData?.promoCode ? userFormData?.promoCode?.replaceAll("-", "")?.toLowerCase() : promoCode
        }
    ];
    const deviceInfo = { device: "website" };
    const paymentData = {
       contactInfo: customerDetails,
        orderSummary: {
            dataPlans: dataPlans,
            totalOrderAmount: price
        },
        iccid: iccid ?? "",
        paymentGateway: "prepaid",
        paymentMethodId: uuidv4(),
        randomId: uuidv4(),
        isEsimCompatible: false,
        deviceInfo
    }
    discountPaymentMutate(paymentData)
   }

    const { refetch,data:voucherTopUpData,isLoading: freeOrderLoader } = useQuery({
        enabled: false, // disables automatic fetching
        queryFn:getTopUpPrepaidBundle.bind(null,data?.name),
        refetchOnWindowFocus: false,
        queryKey:"TopUp",
        cacheTime: 0,
        onSuccess: data => {
            if (data?.length > 0) {
                // setTopup(true)
            } else {
                showToast("error", redeemVoucher.VOUC013);
            }
        },
        onError: (o) => showToast("error", o?.message)
    });

    const topUpExistingESIM = ()=>{
        setTopup(true)
        /* if(!isAuth){
            dispatch(storeVoucherInfo(userFormData?.promoCode))
            navigate("/signin#topup");
            return
        }else{
            refetch()
         }  */
    }

        return (
        <>
            <Layout>
                <section className="redeemvoucher-section">
                    {isLoading || discountPaymentIsLoading || freeOrderLoader  ? <div className="loader"><span><i className="pi pi-spin pi-spinner" ></i>{labels?.LBLN0266}...</span> </div>:null}
                    <div className="grid">
                        <div className="col-12 text-center">
                            <h1>{redeemVoucher?.VOUC001}</h1>
                            <p>{redeemVoucher?.VOUC002}</p>
                         {!topup ? <>
                            <div className="redeemvoucher-wrapper">
                                {/* Form section starts here */}
                                <Formik
                                    initialValues={{
                                        email: userData?.email ?? "",
                                        promoCode: promoCode ?? "",
                                    }}
                                    validationSchema={redeemVoucherValidationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                       mutate({...values,promoCode:values?.promoCode?.replaceAll("-", "")?.toLowerCase()});
                                       setUserFormData(values)
                                       setSubmitting(false);
                                    }}
                                >
                                    <Form autoComplete="off" className="form-block">
                                        <div className="grid">
                                            <div className="col-12 md:col-12">
                                                <Input
                                                    name={"promoCode"}
                                                    type={"promoCode"}
                                                    aria-describedby={"promoCode"}
                                                    maxLength={30}
                                                    placeholder={redeemVoucher?.VOUC003}
                                                />
                                            </div>
                                            <div className="col-12 md:col-12">
                                                <Input
                                                    name={"email"}
                                                    type={"email"}
                                                    disabled={userData?.email}
                                                    aria-describedby={"email"}
                                                    placeholder={labels?.LBLN0209}
                                                />

                                            </div>
                                            <div className="col-12 pb-0">
                                                <PR.Button
                                                    disabled={isLoading}
                                                    label={redeemVoucher?.VOUC001}
                                                    type="submit"
                                                    className="redeemvoucher-button w-12"
                                                />

                                            </div>
                                        </div>


                                    </Form>
                                </Formik>
                                {/* Form section ends here */}                  
                            </div>
                            
                           {!!data &&  
                            <div className="redeemvoucher-wrapper">
                                <VoucherBundleDetails createOrTopupEsim={createOrTopupEsim} discountPaymentIsLoading={discountPaymentIsLoading} topUpExistingESIM={topUpExistingESIM} userFormData={userFormData} bundle={data?.bundle} topupData={data?.topup} />
                            </div>}
                         </>: <div className="redeemvoucher-wrapper"> <RedeemVoucherTopUp createOrTopupEsim={createOrTopupEsim} voucherTopUpData={data?.topup?.esims}/></div>}
                            
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default RedeemVoucher;
