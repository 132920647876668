import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth.slice";
import country from "./country.slice";
import language from "./language.slice";

const store = configureStore({
    reducer:{
        auth,
        country,
        language
    }
});

export default store