
import * as PR from "../../prime-modules/index";
import './Bundle.scss'
import { useState } from "react";
import { useSelector } from "react-redux";
import BundlePopupHeader from "./BundlePopupHeader";
import BundlePopupDetailsList from "./BundlePopupDetailsList";
import RoamingPopup from "./RoamingPopup";
import NetworksPopup from "./NetworksPopup";

const BundleDetailsPopup = ({ data, currency, onClickBuyNow, onClickCancle, doNotSell }) => {

    const {labels} = useSelector(state=>state.language?.activelang);

    const [visible, setVisible] = useState(false);
    const [networkDialog, setNetworkDialog] = useState(null);

    if (!data) return

    const { roamingEnabled, countries } = data ?? {}

    return <div>
    <PR.Dialog closeOnEscape={true}  dismissableMask header={<BundlePopupHeader currency={currency} labels={labels} redeemPage={false} {...data}/>} 
    visible={!!data} blockScroll={true} draggable={false} onHide={() => { if (!data) return; onClickCancle(false); }} className="bundle-detail-blk hide-close">
      <BundlePopupDetailsList labels={labels} setNetworkDialog={setNetworkDialog} {...data} setVisible={setVisible}/>
      <div className="flex">
        <PR.Button onClick={onClickCancle?.bind(null, null)} label={labels?.LBLN0175} className="button button-secondary mr-2" />
        <PR.Button onClick={onClickBuyNow?.bind(null, data)} label={labels?.LBLN0032} className="button button-primary ml-2" disabled={doNotSell} />
      </div>
    </PR.Dialog>

    <RoamingPopup labels={labels} setVisible={setVisible} visible={visible} roamingEnabled={roamingEnabled} />
    <NetworksPopup labels={labels} setVisible={setVisible} networkDialog={networkDialog} roamingEnabled={roamingEnabled} countries={countries} setNetworkDialog={setNetworkDialog} />
    </div>
}


export default BundleDetailsPopup;