import React from "react";
import { useSelector } from "react-redux";
import { checkProviderCode } from "../../utils/reuse";

const BundlePopupRedeemFooter = ({providerCode, createOrTopupEsim, topUpExistingESIM, topupData }) => {
  const { redeemVoucher } = useSelector((state) => state.language?.activelang);
  const {isAuthenticated:isAuth} = useSelector((state) => state.auth);
  return (
    <div className={` grid align-items-center justify-content-end`} >
      {isAuth && topupData?.esims?.length > 0 &&
        <div className="col-12 md:col-6 ">
          <div className={checkProviderCode(providerCode) ? "voucher" : "voucher-disable"}>
            <div
              className="flex align-items-center justify-content-between"
              onClick={() => providerCode !== 2 ? topUpExistingESIM() : {}}
            >
              <div className="card flex align-items-center gap-3">
                <div className="icon">
                  <i className="pi pi-refresh"></i>
                </div>
                <div className="text">
                  <h2>
                    {redeemVoucher.VOUC010} <br /> {redeemVoucher.VOUC011}
                  </h2>
                </div>
              </div>
              <i className="pi pi-arrow-right"></i>
            </div>
          </div>
        </div>
      }
      <div className="col-12 md:col-6">
      <div className="voucher">
        <div className="flex align-items-center justify-content-between" onClick={() => createOrTopupEsim()}>
          <div className="card flex align-items-center gap-3">
            <div className="icon">
              <i className="pi pi-plus"></i>
            </div>
            <div className="text">
              <h2>
                {redeemVoucher.VOUC008}  <br /> {redeemVoucher.VOUC009}
              </h2>
            </div>
          </div>
          <i className="pi pi-arrow-right"></i>
        </div>
      </div>
    </div>    
    </div>
  );
};

export default BundlePopupRedeemFooter;
