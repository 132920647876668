import * as Yup from "yup";
import { parsePhoneNumber } from 'react-phone-number-input';

Yup.addMethod(Yup.string, "phoneNumber", function (message) {
  return this.test("phoneNumber", message, function (value, data) {
    const { path, createError } = this;

    try {
      // Clean the phone number by removing any dashes
        let number ;
        if(value.includes("-")) number = `+${data.originalValue.replace("-","")}`
      // Parse the phone number
      const phoneNumber = parsePhoneNumber(number || value);
      // Check if the phone number is valid
      if (!phoneNumber || !phoneNumber.isValid()) {
        return createError({
          path,
          message: message || "Invalid phone number",
        });
      }

      return true;
    } catch (error) {
      return createError({ path, message: message || "Invalid phone number" });
    }
  });
});

 const firstNameSchema =
  Yup.string()
    .trim()
    .required('VLDN0007')
    .matches(/^[A-Za-z\s]+$/, 'VLDN0018')
    .min(3, 'VLDN0008')
    .max(20, 'VLDN0008');

 const lastNameSchema =
  Yup.string()
    .trim()
    .required('VLDN0009')
    .matches(/^[A-Za-z\s]+$/, 'VLDN0018')
    .min(3, 'VLDN0010')
    .max(20, 'VLDN0010');

 const emailSchema =
  Yup.string()
    .trim()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/,
      'VLDN0006'
    )
    .email('VLDN0006')
    .required('VLDN0005');

 const phoneSchema =
  Yup.string().required('VLDN0021').phoneNumber('VLDN0019');
    

 const passwordSchema =
  Yup.string()
    .trim()
    .required('VLDN0001')
    .min(7, 'VLDN0012')
    .max(20, 'VLDN0012')
    .matches(/\d/, 'VLDN0012')
    .matches(/[A-Za-z]/, 'VLDN0012')
    .matches(/^\S*$/, 'VLDN0020')
    .strict(true);
 const confirmPasswordSchema =
  Yup.string()
    .trim()
    .required('VLDN0003')
    .matches(/^\S*$/, 'VLDN0020')
    .when("password", (password, schema) => {
      return password
        ? schema.oneOf([Yup.ref("password")], 'VLDN0004')
        : schema;
    });

 const messageSchema = Yup.string().required('VLDN0015').test(
  'no-spaces',
  'VLDN0015',
  (value) => value.trim().length > 0
);

const promoCodeSchema =
  Yup.string()
    .trim()
    .required('VLDN0033');

export const signUpValidationSchema = Yup.object().shape({
    firstName: firstNameSchema,
   // lastName: lastNameSchema,
    email: emailSchema,
   // phone: phoneSchema,
    password: passwordSchema,
    confirmPassword: confirmPasswordSchema
});

export const cardPaymentSchema = Yup.object().shape({
  email: emailSchema,
});

export const freepaymentSchema = Yup.object().shape({
  firstName: firstNameSchema,
  email: emailSchema
});

export const siginValidationSchema = Yup.object().shape({
    email: emailSchema,
    password: passwordSchema,
});

export const changePasswordValidationSchema = Yup.object().shape({
  password: passwordSchema,
  confirmPassword: confirmPasswordSchema
});

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: emailSchema,
});

export const resetPasswordValidationSchema = Yup.object().shape({
  password: passwordSchema,
  confirmPassword: confirmPasswordSchema
});

export const otpValidateSchema =Yup.object().shape({
  otp: Yup.string().trim()
    .required('VLDN0022')
    .min(6, 'VLDN0023')
    .max(6, 'VLDN0023')
    .matches(/^[0-9\b]+$/, 'VLDN0024')
});

export const profileValidationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  //lastName: lastNameSchema,
  email: emailSchema,
  //phone: phoneSchema,
});


export const contactUsValidationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  email: emailSchema,
  body: messageSchema,
});

export const affiliateSignUpSchema =  Yup.object().shape({
  email : emailSchema
})

export const redeemVoucherValidationSchema = Yup.object().shape({
  email: emailSchema,
  promoCode: promoCodeSchema,
});
