import React from 'react';
import './i18n';
// **** Main CSS **** //
import './assets/scss/main.scss';
import Navigation from './navigation';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import store from './store';
import { envConfig } from './services/api';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MaintaincePageProvider from './pages/503/provider';
import { LoadScript } from '@react-google-maps/api';
import { ToastProvider } from './context/ToastContext';

const lib = ['places']
const apiKey  = envConfig.REACT_APP_GOOGLE_MAP_API_KEY;

function App() {
  const queryClient = new QueryClient()
  return (
    <Provider store={store} >
      <GoogleOAuthProvider clientId={envConfig.REACT_APP_GOOGLE_AUTH_CLIENT_KEY}>
        <QueryClientProvider client={queryClient}>
          <LoadScript  scriptjs={false} googleMapsApiKey={apiKey} libraries={lib} loadingElement={<></>}>
            <MaintaincePageProvider>
              <ToastProvider>
              <Navigation />
              </ToastProvider>
            </MaintaincePageProvider>
          </LoadScript>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;