import React from 'react'
import { Link } from 'react-router-dom';
import * as PR from "../../prime-modules/index";

const AffiliateSection = ({title, titleFirst,titleLast, affiliate, page}) => {
    
  return (
    <>
    <section className="affiliate-section">
        {title && <h2 className='title-h2'>{title}</h2>}
        <Link to={page}>
            <div className="affiliate-container">
                <div className="grid">
                    <div className="col-12 lg:col-8">
                        <PR.Image src={affiliate} alt="Affiliate Program"/>
                    </div>
                    <div className="col-12 lg:col-4 text-right right-block">
                        <Link to={page} target='_blank'>
                            <div className="arrow-icon">
                                <i className="pi pi-arrow-up-right"></i>
                            </div>
                        </Link>
                        <h2 style={{color:'black'}}>{titleFirst} <br /> 
                        {titleLast}</h2>
                    </div>
                </div>
            </div>
        </Link>
    </section>
    </>
  )
}

export default AffiliateSection