import React from "react";

const Loading = () => {
  return (
    <div className="loading-div">
      <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
    </div>
  );
};

export default Loading;
