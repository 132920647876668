import { createSlice } from "@reduxjs/toolkit";
import { AFID, USER_SESSION_DATA } from "../utils/localstore";
  
const randomId = JSON.parse(localStorage.getItem('CHECKOUT-RANDOM-ID') ?? null);

const userData = JSON.parse(localStorage.getItem(USER_SESSION_DATA) ?? null);
const afid = JSON.parse(localStorage.getItem(AFID) ?? null);
const isAuthenticated = !!userData;


const initialState = { isAuthenticated, userData, isTopupVoucher : false, promoCode:"", afid, randomId }

const authSlice = createSlice({
    name:'authentication',
    initialState,
    reducers:{
        login(state,{payload}){
            state.isAuthenticated  = true;
            state.isTopupVoucher  = payload.isTopupVoucher;
            state.userData = payload;
            localStorage.setItem(USER_SESSION_DATA,JSON.stringify(payload))
        },
        updateUserData(state,{payload}){
            state.userData = payload;
            localStorage.setItem(USER_SESSION_DATA,JSON.stringify(payload))
        },
        logout(state){
            state.isAuthenticated  = false;
            state.isTopupVoucher  = false;
            state.userData = {};
            state.afid = "";
            state.randomId = "";
            localStorage.removeItem(USER_SESSION_DATA)
            localStorage.removeItem(AFID);
            localStorage.removeItem('CHECKOUT-RANDOM-ID');
        },
        storeVoucherInfo(state,{payload}){
            state.promoCode  = payload;
        },
        removeVoucherCode(state) {
            state.promoCode = "";
        },
        storeAfid(state,{payload}){
            state.afid = payload;
            localStorage.setItem(AFID,JSON.stringify(payload))
        },
        removeAfid(state) {
            state.afid = "";
            localStorage.removeItem(AFID);
        },
        setRandomIdForPayment(state, action) {
            state.randomId = action.payload
            localStorage.setItem("CHECKOUT-RANDOM-ID", JSON.stringify(action.payload))
        },
        removeRandomIdInPayment(state) {
            state.randomId = "";
            localStorage.removeItem('CHECKOUT-RANDOM-ID');
        },
    }
});
export const { logout, login, updateUserData, storeVoucherInfo, removeVoucherCode, storeAfid, removeAfid, setRandomIdForPayment, removeRandomIdInPayment} = authSlice.actions
export default authSlice.reducer