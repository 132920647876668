import React, { useEffect, useState } from "react";
import { isIOS, osVersion, isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EsimActivationDialog from "../../components/myplan/EsimActivationDialog";
import { formattedDate, setPrice } from "../../utils/reuse";
import Layout from "../../components/layout/Layout";
import * as PR from "../../prime-modules/index";
import "./PaymentSuccess.scss";
import getAssets from "../../assets/data/brandingAssets.json";
import { sucessIcon } from "../../assets/images";
import { removeAfid, removeRandomIdInPayment } from "../../store/auth.slice";
import { useMutation } from "react-query";
import { confirmPaymentAPI } from "../../services/api";
import { useToast } from "../../context/ToastContext";

let { green_tick } = getAssets.payment;

const qrUrl = "data:image/png;base64,";

function base64ToBlob(base64, contentType) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}

const PaymentSuccessNew = () => {
  const { afid } = useSelector((state) => state.auth);
  const {
    labels,
    myAccount: account,
    redeemVoucher,
  } = useSelector((state) => state.language?.activelang);
  const [qrCodeVisible, setQrCodeVisible] = useState(false);
  const [activeeSIM, setActiveeSIM] = useState(false);
  const [canShare, setCanShare] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search, pathname, state } = useLocation();
  const {showToast} = useToast();
  
  const { mutate, isLoading } = useMutation(confirmPaymentAPI, {
    onSuccess: (data) => {
      navigate(pathname, {replace:true, state: {...data}});
    },
    onError: er => {
      showToast("error", er?.message);
    }
});

  useEffect(() => {
    if(!state && !!search) {
      mutate(search)
      navigate(pathname, {replace: true})
    }
  }, [mutate, navigate, pathname, search, state])

  useEffect(() => {
    dispatch(removeRandomIdInPayment());
  }, [dispatch]);

  useEffect(() => {
    // Check if the Web Share API is supported and can share files
    if (navigator.share && navigator.canShare) {
      setCanShare(true);
    }
  }, []);

  useEffect(() => {
    if (afid) {
      dispatch(removeAfid());
    }
  }, [afid, dispatch]);

  const handleShareClick = async () => {
    const blob = base64ToBlob(state?.qrCode, "image/png");
    const file = new File([blob], "qrcode.png", { type: "image/png" });

    if (navigator.canShare({ files: [file] })) {
      try {
        await navigator.share({
          title: "Shared Image",
          text: "Here is the QR Code you wanted to see:",
          files: [file],
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.error("Web Share API is not supported or cannot share files.");
    }
  };
  return (
    <>
      <Layout>
        
        <section className="payment-success-section relative">
        {isLoading || !state
        ? <div className="full-window-loading payment-loading">
          <i className="pi pi-spin pi-spinner"></i>
          <h2 color="white" style={{color:"white"}}>{account.ACTN0037}</h2>
        </div>
        : <div className="grid">
        <div className="col-12 md:col-5">
          {state?.qrCode && (
            <div className="qrcode-block">
              <>
                <div
                  className={`qrcode-wrapper ${qrCodeVisible ? "show-qr" : ""
                    }`}
                >
                  <PR.Image
                    src={qrUrl + state?.qrCode}
                    alt="Scan the QR Code"
                    className="qrcode"
                  />
                  {!qrCodeVisible && (
                    <span
                      className="eye-icon"
                      onClick={() => setQrCodeVisible(true)}
                    >
                      <i className="pi pi-eye"></i>
                    </span>
                  )}
                </div>
                <h2>{labels?.LBLN0277}</h2>
                <p>{labels?.LBLN0278}</p>
                <div className="payment-details">
                  {isMobile && isIOS && osVersion >= "17.4" && (
                    <PR.Button
                      onClick={() => setActiveeSIM(true)}
                      type="button"
                      label={labels?.LBLN0057}
                      className="download-button"
                    />
                  )}
                </div>
              </>
            </div>
          )}
          {!state?.qrCode && state?.status === "in_progress" && (
            <div className="qrcode-block">
              <p className="order-in-progress">{labels.LBLN0318}</p>
            </div>
          )}
        </div>
        <div className="col-12 md:col-7">
          <div className="payment-details-block">
            <ul className="flex align-items-center justify-content-between header-ul">
              <li className="flex align-items-center gap-3">
                <PR.Image
                  src={state?.redeemVoucher ? sucessIcon : green_tick}
                  alt="Payment Success"
                  className="success"
                />
                <div className="card">
                  <h2>
                    {state?.redeemVoucher
                      ? redeemVoucher.VOUC014
                      : labels?.LBLN0279}
                  </h2>
                  {!!state?.totalOrderAmount && (
                    <p>{setPrice(state?.totalOrderAmount)}</p>
                  )}
                </div>
              </li>
              <li className="flex align-items-center gap-5">
                {canShare ? (
                  <span>
                    <i
                      onClick={handleShareClick}
                      className="pi pi-share-alt"
                    ></i>{" "}
                  </span>
                ) : null}
                <a
                  href={qrUrl + state?.qrCode}
                  download={"QR_Code.png"}
                  style={{ color: "white" }}
                >
                  <span>
                    <i className="pi pi-download"></i>
                  </span>
                </a>
              </li>
            </ul>
            <ul className="flex align-items-center justify-content-between">
              <li>{account?.ACTN0004}</li>
              <li>{state?.transactionId}</li>
            </ul>
            <ul className="flex align-items-center justify-content-between">
              <li>{account?.ACTN0003}</li>
              <li>
                <span className="completed">{state?.transactionStatus}</span>
              </li>
            </ul>
            <ul className="flex align-items-center justify-content-between">
              <li>{account?.ACTN0006}</li>
              <li>{state?.paymentGateway}</li>
            </ul>
            <ul className="flex align-items-center justify-content-between">
              <li>{account?.ACTN0094}</li>
              <li>{formattedDate(state?.completedTs)}</li>
            </ul>
          </div>
          {
            <div className="payment-details-block mt-5">
              <ul className="block align-items-center pt-0">
                <li className="full-width">
                  <PR.Button
                    onClick={() => navigate("/my-plans", { state: 1 })}
                    label={account?.ACTN0096}
                    className="view-button"
                  />
                </li>
                <li className="mt-2 text-center">
                  <PR.Button
                    onClick={() => navigate("/#plans")}
                    label={account?.ACTN0097}
                    className="underline-button"
                  />
                </li>
              </ul>
            </div>
          }
        </div>
      </div>}
          
        </section>
      </Layout>

      {state?.paymentResponse?.smdpAddress && state?.paymentResponse?.matchingId && (
        <EsimActivationDialog
          smdpAddress={state?.paymentResponse?.smdpAddress}
          matchingId={state?.paymentResponse?.matchingId}
          activeeSIM={activeeSIM}
          setActiveeSIM={setActiveeSIM}
        />
      )}
    </>
  );
};

export default PaymentSuccessNew;
