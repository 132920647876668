import React from 'react';
import { useSelector } from 'react-redux';
import { getImageSrc } from '../../utils/reuse';
import "./Affiliate.scss";
import * as PR from "../../prime-modules/index";
import Layout from "../../components/layout/Layout";
import { Link } from "react-router-dom";
import AffiliateSection from '../../components/home/AffiliateSection';
import getAssets from "../../assets/data/brandingAssets.json"


const affiliateURL = process.env.REACT_APP_AFFILIATE_DOMAIN_URL;


const Affiliate = () => {
    const {affiliate} = useSelector(state=>state.language?.activelang);
    let how_it_works = getImageSrc(affiliate?.AFFN0069, getAssets.affiliate.how_it_works)
    let travel_bloggers = getImageSrc(affiliate?.AFFN0063, getAssets.affiliate.travel_bloggers)
    let cruise_operators = getImageSrc(affiliate?.AFFN0066, getAssets.affiliate.cruise_operators)
    let multi_national = getImageSrc(affiliate?.AFFN0065, getAssets.affiliate.multi_national)
    let event_planer = getImageSrc(affiliate?.AFFN0064, getAssets.affiliate.event_planer)
    let travel_agents = getImageSrc(affiliate?.AFFN0067, getAssets.affiliate.travel_agents)
    let affiliate_program = getImageSrc(affiliate?.AFFN0068, getAssets.affiliate.affiliate_program)
    return (
    <>
        <Layout>
            {/*** Header Section ***/}
            <section className="header-banner-section">
                <div className="header-text text-center">
                    <h1 className='affiliate-h1'>{affiliate?.AFFN0001} <br/>{affiliate?.AFFN0035}</h1>
                    <p>{affiliate?.AFFN0002}</p>
                    <Link target='_blank' to={`${affiliateURL}/affiliate-signup`}>
                        <PR.Button label={affiliate?.AFFN0003} className="open-button" />
                    </Link>
                </div>
            </section>

            {/*** How it works Section ***/}
            <section className="how-it-works-section">
                <div className="container">
                    <h2>{affiliate?.AFFN0004}</h2>
                    <div className="flex gap-5">
                        <div className="left">
                            <PR.Image src={how_it_works} alt={affiliate?.AFFN0004}/>
                        </div>
                    </div>
                    <p style={{textAlign:"center"}} className='mt-6 mb-6'>{affiliate?.AFFN0005}</p>
                    <div className="grid mt-8">
                        <div className="col-12 md:col-4">
                            <div className='info-block'>
                                <span>1</span>
                                <h3>{affiliate?.AFFN0021}</h3>
                                <p>{affiliate?.AFFN0022}</p>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">
                            <div className='info-block'>
                                <span>2</span>
                                <h3>{affiliate?.AFFN0023}</h3>
                                <p>{affiliate?.AFFN0024}</p>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">
                            <div className='info-block last'>
                                <span>3</span>
                                <h3>{affiliate?.AFFN0025}</h3>
                                <p>{affiliate?.AFFN0026}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*** Who is it for Section ***/}
            <section className="who-is-section">
                <div className="container">
                    <h2>{affiliate?.AFFN0006}</h2>
                    <div className="flex flex-wrap gap-5 custom-margin">
                        <div className="option flex-1 flex flex-column justify-content-center">
                            <PR.Image src={travel_bloggers} alt={affiliate?.AFFN0007} />
                            <p>{affiliate?.AFFN0007} <br/> {affiliate?.AFFN0061}</p>
                        </div>
                        <div className="option flex-1 flex flex-column justify-content-center">
                            <PR.Image src={event_planer} alt={affiliate?.AFFN0008 +' '+ affiliate?.AFFN0009}/>
                            <p>{affiliate?.AFFN0008} <br/> {affiliate?.AFFN0009}</p>
                        </div>
                        <div className="option flex-1 flex flex-column justify-content-center">
                            <PR.Image src={multi_national} alt={affiliate?.AFFN0010 +' '+ affiliate?.AFFN0011}/>
                            <p>{affiliate?.AFFN0010} <br/> {affiliate?.AFFN0011}</p>
                        </div>
                        <div className="option flex-1 flex flex-column justify-content-center">
                            <PR.Image src={cruise_operators} alt={affiliate?.AFFN0012 +' '+ affiliate?.AFFN0013}/>
                            <p>{affiliate?.AFFN0012} <br/> {affiliate?.AFFN0013}</p>
                        </div>
                        <div className="option flex-1 flex flex-column justify-content-center">
                            <PR.Image src={travel_agents} alt={affiliate?.AFFN0014 +' '+ affiliate?.AFFN0015}/>
                            <p>{affiliate?.AFFN0014} <br/> {affiliate?.AFFN0015}</p>
                        </div>
                    </div>
                    {/* <Link to={"/affiliate-signup"}>
                        <PR.Button label={affiliate?.AFFN0016} className="apply-button" />
                    </Link> */}
                   
                </div>
            </section>

            {/*** Affiliate Section ***/}
            <AffiliateSection title={affiliate?.AFFN0027} titleFirst={affiliate?.AFFN0017} titleLast={affiliate?.AFFN0018} subText={affiliate?.AFFN0019} affiliate={affiliate_program} page={`${affiliateURL}/affiliate-signup`} /> 
      
        </Layout>
    </>
  );
};

export default Affiliate;