import React from "react";
import Layout from "../../components/layout/Layout";
import "./CompatibleDevices.scss";
import * as PR from "../../prime-modules/index";
import { useSelector } from "react-redux";

const CompatibleDevices = () => {
    const {labels} = useSelector(state=>state.language?.activelang);
    return (
    <>
        <Layout>
            <section className="compatible-devices-section">
                <div className="grid">
                    <div className="col-12 text-center">
                        <h1>{labels?.LBLN0113}</h1>
                        <p>{labels?.LBLN0114}</p>
                    </div>
                </div>
                <div className="devices-section">
                    <p><span>{labels?.LBLN0115}:</span> {labels?.LBLN0116} <span>{labels?.LBLN0117}</span> {labels?.LBLN0119}</p>
                    <PR.TabView>
                        <PR.TabPanel header={labels?.LBLN0118}>
                            <div className="device-panel">
                                <ul>
                                    <li>iPhone XR</li>
                                    <li>iPhone XS</li>
                                    <li>iPhone XS Max</li>
                                    <li>iPhone 11</li>
                                    <li>iPhone 11 Pro</li>
                                    <li>iPhone SE 2 (2020)</li>
                                    <li>iPhone SE 3 (2022)</li>
                                    <li>iPhone 12</li>
                                    <li>iPhone 12 Mini</li>
                                    <li>iPhone 12 Pro</li>
                                    <li>iPhone 12 Pro Max</li>
                                </ul>
                                <ul>
                                    <li>iPhone 13</li>
                                    <li>iPhone 13 Mini</li>
                                    <li>iPhone 13 Pro</li>
                                    <li>iPhone 13 Pro Max</li>
                                    <li>iPhone 14</li>
                                    <li>iPhone 14 Plus</li>
                                    <li>iPhone 14 Pro</li>
                                    <li>iPhone 14 Pro Max</li>
                                    <li>iPhone 15</li>
                                    <li>iPhone 15 Plus</li>
                                    <li>iPhone 15 Pro</li>
                                    <li>iPhone 15 Pro Max</li>
                                </ul>
                                <ul>
                                    <li>iPad Pro 11″ (model A2068, from 2020)</li>
                                    <li>iPad Pro 12.9″ (model A2069, from 2020)</li>
                                    <li>iPad Air (model A2123, from 2019)</li>
                                    <li>iPad (model A2198, from 2019)</li>
                                    <li>iPad Mini (model A2124, from 2019)</li>
                                </ul>
                            </div>
                        </PR.TabPanel>
                        <PR.TabPanel header={labels?.LBLN0120}>
                            <div className="device-panel">
                                <ul>
                                    <li>Samsung Galaxy S20</li>
                                    <li>Samsung Galaxy S20+</li>
                                    <li>Samsung Galaxy S20 Ultra</li>
                                    <li>Samsung Galaxy S21</li>
                                    <li>Samsung Galaxy S21+ 5G</li>
                                    <li>Samsung Galaxy S21+ Ultra 5G</li>
                                </ul>
                                <ul>
                                    <li>Samsung Galaxy S22</li>
                                    <li>Samsung Galaxy S22+</li>
                                    <li>Samsung Galaxy S22 Ultra</li>
                                    <li>Samsung Galaxy Note 20</li>
                                    <li>Samsung Galaxy Note 20 Ultra 5G</li>
                                    <li>Samsung Galaxy Fold</li>
                                </ul>
                                <ul>
                                    <li>Samsung Galaxy Z Fold2 5G</li>
                                    <li>Samsung Galaxy Z Fold3 5G</li>
                                    <li>Samsung Galaxy Z Fold4</li>
                                    <li>Samsung Galaxy Z Flip</li>
                                    <li>Samsung Galaxy Z Flip3 5G</li>
                                    <li>Samsung Galaxy Z Flip4</li>
                                </ul>
                            </div>
                        </PR.TabPanel>
                        <PR.TabPanel header={labels?.LBLN0121}>
                            <div className="device-panel">
                                <ul>
                                    <li>Google Pixel 2</li>
                                    <li>Google Pixel 2 XL</li>
                                    <li>Google Pixel 3</li>
                                    <li>Google Pixel 3 XL</li>
                                    <li>Google Pixel 3a</li>
                                    <li>Google Pixel 3a XL</li>
                                </ul>
                                <ul>
                                    <li>Google Pixel 4</li>
                                    <li>Google Pixel 4a</li>
                                    <li>Google Pixel 4 XL</li>
                                    <li>Google Pixel 5</li>
                                    <li>Google Pixel 5a</li>
                                </ul>
                                <ul>
                                    <li>Google Pixel 6</li>
                                    <li>Google pixel 6a</li>
                                    <li>Google Pixel 6 Pro</li>
                                    <li>Google Pixel 7</li>
                                    <li>Google Pixel 7 Pro</li>
                                </ul>
                            </div>
                        </PR.TabPanel>
                        <PR.TabPanel header={labels?.LBLN0122}>
                            <div className="device-panel">
                                <div>
                                    <h2>{labels?.LBLN0261}</h2>
                                    <ul>
                                        <li>Huawei P40</li>
                                        <li>Huawei P40 Pro</li>
                                        <li>Huawei Mate 40 Pro</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2>{labels?.LBLN0262}</h2>
                                    <ul>
                                        <li>Oppo Find X3 Pro</li>
                                        <li>Oppo Reno 5A</li>
                                        <li>Oppo Find X5</li>
                                        <li>Oppo Find X5 Pro</li>
                                    </ul>
                                </div>
                            </div>
                        </PR.TabPanel>
                        <PR.TabPanel header={labels?.LBLN0123} className="last-tab">
                            <div className="card custom-margin">
                                <h2>{labels?.LBLN0124}</h2>
                                <div className="device-panel mt-5">
                                    <ul>
                                        <li>Microsoft Surface Pro X</li>
                                        <li>Microsoft Surface Go 2</li>
                                        <li>Microsoft Surface Pro LTE Advanced</li>
                                        <li>Acer Swift 3</li>
                                        <li>Acer Swift 7</li>
                                        <li>Asus Mini Transformer</li>
                                    </ul>
                                    <ul>
                                        <li>Asus NovaGo</li>
                                        <li>Asus VivoBook Flip 14</li>
                                        <li>HP Elitebook G5</li>
                                        <li>HP Probook G5</li>
                                        <li>HP Zbook G5</li>
                                    </ul>
                                    <ul>
                                        <li>HP Specter Folio 13</li>
                                        <li>Lenovo Yoga C630</li>
                                        <li>Lenovo Miix 630</li>
                                        <li>Lenovo Yoga 520</li>
                                        <li>Samsung Galaxy Book 2</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card custom-margin">
                                <h2>{labels?.LBLN0123}</h2>
                                <div className="device-panel mt-5">
                                    <ul>
                                        <li>Motorola Razr 2019</li>
                                        <li>Motorola Razr 5G</li>
                                        <li>Gemini PDA</li>
                                        <li>Rakuten Mini</li>
                                        <li>Rakuten Big-S</li>
                                        <li>Rakuten Big</li>
                                    </ul>
                                    <ul>
                                        <li>Rakuten Hand</li>
                                        <li>Rakuten Hand 5G</li>
                                        <li>Sony Xperia 10 III Lite</li>
                                        <li>Sony Xperia 10 IV</li>
                                        <li>Xperia 1 IV</li>
                                        <li>Surface Pro X</li>
                                    </ul>
                                    <ul>
                                        <li>Honor Magic 4 Pro</li>
                                        <li>Fairphone 4</li>
                                        <li>Sharp Aquos Sense6s</li>
                                        <li>Sharp Aquos Wish</li>
                                        <li>Xiaomi 12T Pro</li>
                                        <li>DOOGEE V30</li>
                                    </ul>
                                </div>
                            </div>
                        </PR.TabPanel>
                    </PR.TabView>
                </div>
            </section>
        </Layout>
    </>
  );
};

export default CompatibleDevices;
