import React from 'react'
import { Link } from 'react-router-dom'
import * as PR from "../../prime-modules/index";
import getAssets from "../../assets/data/brandingAssets.json"

function ContectInfo({home}) {
let { whats_app } = getAssets.icons
return (
    <section id="contact" className="get-in-touch-section">
        <div className="card">
            <div className='grid custom-margin1'>
                <div className="col-12 lg:col-4">
                    <div className='info-box'>
                        <h3>{home?.HOMEN0065}</h3>
                        <p>{home?.HOMEN0066}</p>
                        <a href={`mailto:${home?.HOMEN0067}`} className="email-link">{home?.HOMEN0067}</a>
                    </div>
                </div>
                <div className="col-12 lg:col-4">
                    <div className='info-box'>
                        <h3>{home?.HOMEN0068}</h3>
                        <p>{home?.HOMEN0069}</p>
                        <Link to={'https://wa.me/message/7H7PZX53WH7PJ1'} target='_blank' className='email-link'>
                            <PR.Image src={whats_app} alt="WhatsApp" className='whatsapp-icon'/>{home?.HOMEN0070}
                        </Link>
                    </div>
                </div>
                <div className="col-12 lg:col-4">
                    <div className='info-box'>
                        <h3>{home?.HOMEN0071}</h3>
                        <p>{home?.HOMEN0072} </p>
                        <a href={`mailto:${home?.HOMEN0073}`} className="email-link">{home?.HOMEN0073}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
)
}

export default ContectInfo