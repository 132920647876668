import { useTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";
import './index.scss';
import * as PR from "../../prime-modules/index";

import faq_en from '../../assets/data/faq_en.json'
import faq_es from '../../assets/data/faq_es.json'
import faq_pt from '../../assets/data/faq_pt.json'
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const matchLoop = value => value.map(i => {
    const v = Object.entries(i)[0][1]
    if (typeof (v) === 'string') return v
    else return v?.join(' ')
}).join(' ')

const highlightedWord = (content, searchTerm) => <span
    dangerouslySetInnerHTML={{
        __html: content.replace(
            new RegExp(`(${searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`, "gi"),
            "<mark>$1</mark>"
        )
    }} 
/>;

const withMatch = value => value.map(i => ({ ...i, QA: i.QA?.map(i => ({ ...i, match: `${i.question} ${matchLoop(i.answer)}`.toLowerCase() })) }));

const faqObject = {
    en: withMatch(faq_en),
    es: withMatch(faq_es),
    pt: withMatch(faq_pt)
}

const FAQ = () => {

    const { i18n: { language: ln } } = useTranslation();
    const {labels} = useSelector(state=>state.language?.activelang);

    const faq = faqObject[ln.split('-')[0]] ?? [];
    const [search, setSearch] = useState('');
    
    const searchedFAQ = faq?.map(tab => tab?.['QA']).flat()?.filter(i => i.match.includes(search.toLowerCase()));
    const location = useLocation();

    useEffect(()=>{
        setTimeout(() => {
            if(location.state){
                const el = document.getElementById(location.state);
                if(!!el) el.scrollIntoView({ behavior: "smooth" });
                location.state = '';
                window.history.replaceState(null, '')
            }
        }, 100);
    },[location.state,location]);

    return <Layout>
        <div className="faq-section">
            <div className="text-center mb-5 pb-5">
                <h1>{labels?.LBLN0136}</h1>
                <p>{labels?.LBLN0137}</p>
            </div>
            <div className="search-input-icon">
                <label htmlFor="search"><i className="pi pi-search" ></i></label>
                <PR.InputText placeholder="Search..." id="search" value={search} onChange={(e) => setSearch(e.target.value)} showClear />
                <span className="pi pi-chevron-right"></span>
            </div>
            <div className="faq-blk mb-5 pb-5">
                {searchedFAQ.length === 0 && <h2 style={{textAlign:'center'}} >{labels?.LBLN0263}</h2>}
                <PR.TabView unstyled className="tablist">
                    <PR.TabPanel>
                        <PR.Accordion activeIndex={!!location.state&&searchedFAQ.findIndex(e=>e.question===location.state)} >
                            {searchedFAQ.map((accord, accordIndex) => <PR.AccordionTab id={accord.question} key={accordIndex} header={highlightedWord(accord?.question, search)}>
                                    {accord?.answer?.map((content, contentIndex) =>
                                        <Content key={contentIndex} search={search} data={content} />
                                    )}
                                </PR.AccordionTab>
                            )}
                        </PR.Accordion>
                    </PR.TabPanel>
                </PR.TabView>
            </div>
        </div>
    </Layout>
}

export default FAQ;

const element = {
    content: (value, s) => <p>{highlightedWord(value, s)}</p>,
    content_heading: (value, s) => <h4>{highlightedWord(value, s)}</h4>,
    olist: (value, s) => <ol>{value?.map((e, i) => <li key={i}>{highlightedWord(e, s)}</li>)}</ol>,
    ulist: (value, s) => <ul>{value?.map((e, i) => <li key={i}>{highlightedWord(e, s)}</li>)}</ul>
}

const Content = ({ data, search }) => {
    const [key, value] = Object.entries(data)[0];
    return <>{element[key]?.(value, search)}</>
}