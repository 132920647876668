
import { useEffect, useState } from "react";
import * as PR from "../../prime-modules/index";

const CountrySearch = ({ onSelected, countries, placeholder, initial }) => {

    const [searchCountry, setSearchCountry] = useState(initial);
    const handleChange = e => {
        if (e?.value?.name) onSelected(e?.value)
        setSearchCountry(e?.value?.name ?? e?.value);
    }

    const onkeydownHandler = e => {
        if (e.key === 'Enter') {
            const dropdownPanel = e.target.closest('.p-dropdown').querySelector('.p-dropdown-panel');
            const highlightedItem = dropdownPanel?.querySelector('.p-highlight');

            if (highlightedItem) {
                const selectedLabel = highlightedItem.innerText;
                const selectedOption = countries.find(el => el.name === selectedLabel);

                if (selectedOption) {
                    setSearchCountry(selectedOption.name);
                    onSelected(selectedOption);
                }
            }

            // const v = countries?.find(e => e?.name?.toLowerCase().startsWith(searchCountry?.toLowerCase()));
            // setSearchCountry(v.name);
            // onSelected(v);
        }
    }

    useEffect(() => {
        setSearchCountry(initial)
    }, [initial]);

    let opt = countries?.filter(e => e?.name?.toLowerCase().startsWith(searchCountry?.toLowerCase()));
    if(!opt.length) opt =  countries?.filter(e => e?.name?.toLowerCase().includes(searchCountry?.toLowerCase()));

    return <div className='dropdown-block'>
        <PR.Dropdown 
            
            value={searchCountry} 
            filterBy="name" onKeyDown={onkeydownHandler} 
            onChange={handleChange} 
            options={countries} 
            optionLabel="name" 
            placeholder={placeholder} 
            editable 
            className="country-dropdown"
        />
        <i className="pi pi-search"></i>
    </div>
}

export default CountrySearch;